import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';


export const LessonCardItem = ({ icon, text }) => {
  const classes = useStyles();
  return (
    <Grid item xs={3} className={classes.item}>
      <Grid container direction="column" justify="center" alignItems="center">
        {icon}
        <Typography variant="subtitle2">
          <Box fontWeight="fontWeightBold">
            {text}
          </Box></Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2),
  }
}));
