import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import { AppProvider } from './context/AppContext';
import { PlanningProvider } from './context/PlanningContext';
import { BookingProvider } from './context/BookingContext';
import { BrowserRouter } from 'react-router-dom';


ReactDOM.render(
  <BrowserRouter>
    <AppProvider>
      <PlanningProvider>
        <BookingProvider>
          <App />
        </BookingProvider>
      </PlanningProvider>
    </AppProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
