import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Card, InputBase } from '@material-ui/core';
import moment from 'moment';

import { Form } from '../common/Form';
import { appBarHeight } from '../../utils/layout';
import { AppContext } from '../../context/AppContext';
import { querySchoolLessons } from '../../utils/gqlQueries';
import {
  ages,
  days,
  levels,
  prepareFilters,
} from '../../utils/filters';
import { formatTime } from '../../utils/date';

/* */
function getLabel(filters, value) {
  const found = filters.find((o) => value === o.value);

  return (found ? found.label : 'Tout public');
}

/* */
const displayLesson = (lesson) => {
  const dayOfWeek = moment(lesson.slot.date, 'x').format('d');
  const day = getLabel(days, parseInt(dayOfWeek));
  const time = formatTime(lesson.slot.startHour);
  return `${day} - ${time} - ${lesson.title} - ${getLabel(ages, lesson.age)} - ${getLabel(levels, lesson.level)}`;
};

/* */
export const Filters = ({ onChange }) => {
  const classes = useStyles();
  const {
    golf,
    fetchApi,
    teachers,
  } = useContext(AppContext);
  const [filters, setFilters] = useState({
    teachers: -1,
    age: ages[0].value,
    day: days[0].value,
  });
  const [lessons, setLessons] = useState([]);
  const [selectedLessonId, setSelectedLessonId] = useState(-1);

  /* */
  const handleChange = (name, value) => {
    if (name === 'lesson') {
      if (value === 0) {
        setSelectedLessonId(0);
        onChange(0);
      } else {
        const lesson = lessons.find((l) => l.slot.id === value);
        setSelectedLessonId((lesson && lesson.slot.id) || -1);
        onChange(lesson || null);
      }
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  /* */
  useEffect(() => {
    setSelectedLessonId(-1);
    getLessons();
  }, [filters]);

  /* */
  const getLessons = () => fetchApi(querySchoolLessons, ({
    recurrent: 1,
    golfId: golf.id,
    ...prepareFilters(filters, true),
  })).then((response) => {
    if (!response || !response.data) return;
    const l = response.data.getSchoolLessons;
    setLessons(l);
    return l;
  });

  const ref = React.createRef()

  const lessonItems = [{ value: -1, label: '-' }];
  lessonItems.push(...lessons.map((l) => {
    return ({
      value: l.slot.id,
      label: displayLesson(l),
      // label: `${moment(l.slot.date, 'x').format('dddd')} - ${formatTime(l.slot.startHour)} - ${l.title}`,
    });
  }));

  return (
    <div ref={ref} className={classes.filter}>
      <Card elevation={0} className={classes.paper}>
        <Form
          className={classes.filters}
          onChange={handleChange}
          fields={[
            {
              name: 'teachers',
              value: filters.teachers,
              items: [{ value: -1, label: 'Tous les professeurs' }]
                .concat(teachers.map((teacher) => ({
                  label: `${teacher.firstname} ${teacher.lastname}`,
                  value: teacher.id
                }))),
              type: 'select',
              selectstyle: <BootstrapInput />
            },
            {
              name: 'age',
              value: filters.age,
              items: ages,
              type: 'select',
              selectstyle: <BootstrapInput />
            },
            {
              name: 'day',
              value: filters.day,
              items: days,
              type: 'select',
              selectstyle: <BootstrapInput />
            },
            {
              name: 'lesson',
              value: selectedLessonId,
              items: lessonItems,
              type: 'select',
              selectstyle: <BootstrapInput />
            },
          ]}>
        </Form>
      </Card>
    </div>
  );
};

/* */
const useStyles = makeStyles((theme) => ({
  paper: {
    zIndex: 1,
    borderBottom: '1px solid #dcdcdc',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 2, 2),
    // paddingTop: 0,
    backgroundColor: 'white',
    color: theme.palette.common.black,
    // [theme.breakpoints.down('md')]: {
    //   left: 0,
    //   width: '100%',
    // },
  },
  filter: {
    position: 'sticky',
    top: appBarHeight,
    zIndex: 1,
  },
  filters: {
    // justifyContent: 'center',
    // flexDirection: 'row',
    width: '100%',
    // padding: '5px 0 20px 20px',
  },
  margin: {
    margin: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(1, 3),
  },
}));

/* */
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid #ced4da',
    padding: '10px 26px 10px 12px',
    marginRight: 10,
    '&:focus': {
      borderRadius: 4,
    },
  },
}))(InputBase);
