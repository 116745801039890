import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';


export const Admin = ({ children, hidden = false }) => {

  const { user } = useContext(AppContext);

  return ((!hidden && user.role === 'admin')
    || (hidden && user.role !== 'admin'))
    ? children
    : null;
}
