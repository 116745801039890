import React from 'react';

/* */
class PrivacyPolicy extends React.Component {
  /* */
  constructor(props) {
    super(props);
    document.title = 'Politique de confidentialité';
  }

  /* */
  render() {
    return (
      <div className="page">
        <h1>Politique de confidentialité</h1>
        <h2>Que signifie données personnelles ?</h2>
        <p>Pour information au sens du RGPD, constitue une « donnée personnelle » toute données relatives à une personne physique qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale</p>
        <h2>Que signifie traitement ?</h2>
        <p>Pour information constitue au sens du RGPD un « traitement », toute opération ou tout ensemble d'opérations effectuées ou non à l'aide de procédés automatisés et appliquées à des données à caractère personnel, telles que la collecte, l'enregistrement, l'organisation, la structuration, la conservation, l'adaptation ou la modification, l'extraction, la consultation, l'utilisation, la communication par transmission, la diffusion ou toute autre forme de mise à disposition, le rapprochement ou l'interconnexion, la limitation, l'effacement ou la destruction.</p>
        <h2>Quel contrôle sur vos données personnelles ?</h2>
        <p>Le contrôle des données personnelles que vous nous communiquez et la responsabilité de vos données personnelles sont effectués conformément à la loi sur la protection des données en vigueur.</p>
        <p>Nous nous engageons à une obligation d'information de l'utilisation qui sera faite par nous des données personnelles, qu'il s'agisse de vos données ou de celles de nos clients ou prospects, fournisseurs.</p>
        <p>Nous avons mis en place un registre des traitements en interne recommandée par la CNIL dans lequel nous avons spécifié la description du traitement, les acteurs, la finalité du traitement, les mesures de sécurité, les catégories de données, et les catégories de personnes concernées.</p>
        <p><strong>Responsable de la protection des données :</strong><br />Nous avons désigné un Responsable de la protection des données qui veillera à ce que vos données personnelles soient systématiquement utilisées de façon transparente, exacte et conforme à la loi.</p>
        <h2>Quelle utilisation de vos données de géolocalisation ?</h2>
        <p>Dans le cadre de l'utilisation de nos applications mobiles, vos données de géolocalisation pourront être utilisée, uniquement si le service le nécessite et si nous avons préalablement obtenu votre accord pour utiliser cette information. Les services de nos applications qui utilisent les données de géolocalisation sont décrits ci-dessous :</p>
        <p><u>Application Ma Station e.Leclerc</u></p>
        <p>Vos données de géolocalisation sont utilisées dans l’application Ma Station e.Leclerc afin de vous permettre de vous servir en carburant aux stations compatibles sans sortir votre téléphone du véhicule. L’application utilise votre localisation dans les stations-service uniquement. Vos coordonnées ne sont ni utilisées ni stockées lorsque vous êtes hors d’une station-service.</p>
        <h2>Qui peut consulter vos données ?</h2>
        <p>Vos données peuvent être transmises au sein de la société APS MANAGEMENT - Prima  et ses sous-traitants éventuels qui seront tenues aux mêmes obligations que Prima à votre égard.</p>
        <p>Nous ne transférons, ne commercialisons et ne troquons en aucun cas vos données à des fins marketings à des parties extérieures.</p>
        <h2>Quelles sont les conditions légales de traitement des données ?</h2>
        <p>Pour chaque traitement spécifique des données personnelles que nous collectons auprès de vous, nous les collectons pour une finalité spécifique.</p>
        <p>Nous pouvons être amenés à collecter des données pour répondre à nos obligations légales et réglementaires.</p>
        <p>Nous nous engageons au recueil clair et non équivoque du consentement de la personne qui nous transmet ses données personnelles, à moins que la collecte des données soit nécessaire au contrat ou qu'elle fasse suite à une obligation légale à laquelle nous sommes soumis ou encore que cela soit justifié par la sauvegarde des intérêts vitaux de la personne ou encore par l'exécution d'une mission d'intérêt public ou la poursuite, par notre structure, d'intérêt légitime,</p>
        <p>Nous ne collectons pas de données sensibles sous réserve de votre consentement explicite.</p>
        <p>Sauf si la législation nous l’impose, nous ne traitons jamais celles relatives à vos origines raciales ou ethniques, vos opinions politiques, votre religion, vos convictions philosophiques ou l’appartenance syndicale, au traitement des données génétiques, votre vie sexuelle ou orientation sexuelle.</p>
        <h2>Quels délais de conservations de vos données ?</h2>
        <p>Les Données Personnelles doivent être conservées sous une forme permettant l’identification des personnes concernées pendant une durée n’excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées ;</p>
        <ul>
          <li>Temps de la relation commerciale (ex : acte d’achat ou jusqu’à l’expiration d’une garantie)</li>
          <li>3 ans à compter du dernier contact pour les données des prospects</li>
          <li>2 ans à compter de la réception de la candidature pour les données des candidats</li>
        </ul>
        <p>Nous conservons vos données personnelles pour la plus longue des durées nécessaires au respect des dispositions légales et réglementaires applicables ou une autre durée compte tenu des contraintes contractuelles ou opérationnelles</p>
        <h2>Quels sont vos droits ?</h2>
        <p>Conformément à la réglementation applicable, vous disposez de différents droits, à savoir</p>
        <p><strong>Droit d’accès :</strong><br />Vous avez le droit de demander des informations sur les données personnelles que nous détenons vous concernant.</p>
        <p><strong>Droit de portabilité :</strong><br />Vous avez le droit d'obtenir une copie de vos données transférées à vous ou à une autre partie dans un format structuré et courant. Cela ne concerne que les données personnelles que vous nous avez transmises.</p>
        <p><strong>Droit de rectification :</strong><br />Vous avez le droit de demander la rectification de vos données personnelles si elles sont inexactes, y compris pour compléter vos données personnelles incomplètes. </p>
        <p><strong>Droit de suppression :</strong><br />Vous avez le droit de supprimer toute donnée personnelle à tout moment dans les conditions légales</p>
        <p><strong>Droit de vous opposer au traitement de vos données sur la base d’un intérêt légitime :</strong><br />Vous avez le droit de vous opposer au traitement de vos données personnelles sur la base d’un intérêt légitime</p>
        <p><strong>Droit de vous opposer au marketing direct :</strong><br />Vous avez le droit de vous opposer au marketing direct</p>
        <p><strong>Droit de restriction :</strong><br />Vous avez le droit de demander une limite l’utilisation de vos données personnelles</p>
        <h2>Comment exercer vos droits ?</h2>
        <p>Nous prenons la protection des données très au sérieux et disposons par conséquent d'un service client dédié pour répondre à vos questions concernant vos droits précités. Vous pouvez les contacter</p>
        <p>Vous devrez alors fournir vos noms, prénom, ainsi qu’une copie de votre pièce d’identité.</p>
        <p><strong>Droit de réclamation auprès d’une autorité de surveillance :</strong><br />Si vous estimez qu’on utilise vos données personnelles de façon inappropriée, vous pouvez nous contacter. Vous avez également le droit de déposer une réclamation auprès de l’autorité de surveillance.</p>
        <h2>Quelles sont les mesures de sécurité de vos données personnelles ?</h2>
        <p>Nous avons mis en place des mesures de sécurité informatiques.</p>
        <p>Nous nous engageons à contacter la CNIL dans les 72h en cas d’atteinte ou risque d’atteinte à vos données personnelles (attaque informatique ; accès aux fichiers par une personne non autorisée ou malveillante.)</p>
        <p>Nous avons informé nos salariés des mesures de précautions vis-à-vis des données personnelles :</p>
        <ul>
          <li>fermer la porte du bureau et verrouiller la session utilisateur en cas d’absence du bureau et/ou du poste de travail</li>
          <li>fermer à clé les tiroirs et meubles dans lesquels sont entreposées les données personnelles (de clients ou de salariés par exemple).</li>
          <li>indiquer à la personne référente pour les données personnelles dans l'entreprise, impérativement et sans délai toute atteinte aux fichiers qui sera constatée</li>
        </ul>
        <h2>Actualisation des données transmises</h2>
        <p>Il est important que les informations que vous nous transmettez soient exactes et à jour et que vous nous informiez sans délai de tout changement significatif vous concernant.</p>
        <br /><br />
      </div>
    );
  }
}

export default PrivacyPolicy;
