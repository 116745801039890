import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Paper, Typography, Hidden } from '@material-ui/core';
import { AppContext } from '../../context/AppContext';
import illustration from './../../assets/dashboard_illustration.png';


export const WelcomeBanner = () => {

  const classes = useStyles();
  const { user } = useContext(AppContext);

  return (
    <Hidden xsDown>
      <Paper className={classes.container} elevation={0}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="column" justify="center" alignItems="flex-start">
              <Grid item>
                <Typography variant="h3">
                  <Box fontWeight="fontWeightBold">
                    Bienvenue, {user.displayName}
                  </Box>
                </Typography>
                <Typography variant="h6">
                  <Box fontWeight="fontWeightLight">
                    Heureux de vous revoir !
                  </Box>
                </Typography>
              </Grid>

            </Grid>
          </Grid>
          <Grid item display={{ dashboard: 'none' }}>
            <img alt="menu" src={illustration} className={classes.image} />
          </Grid>
        </Grid>
      </Paper>
    </Hidden>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.primary.main,
    margin: theme.spacing(0, 0, 3, 0),
    padding: theme.spacing(0, 0, 0, 3),
    color: theme.palette.common.white,
    height: 175,
    width: '100%'
  },
  image: {
    height: 175,
    borderBottomRightRadius: 8
  }
}));
