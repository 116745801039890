import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import { AppBar, Hidden, IconButton, Toolbar, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { DisconnectButton } from './DisconnectButton';
import { AppContext } from '../../context/AppContext';
import { appBarHeight } from '../../utils/layout';
import { WeatherMiniWidget } from './WeatherMiniWidget'
import '../../theme/theme.css'


export const AppHeader = ({ open, openDrawer }) => {

  const { golf } = useContext(AppContext);
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={`not-printed ${classes.appBar}`}>
      <Toolbar>
        <Hidden lgUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={openDrawer}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}>
            <Menu />
          </IconButton>
        </Hidden>
        <Typography variant="h6" noWrap className={classes.title}>
          {golf.name}
        </Typography>
        <Hidden xsDown implementation="css">
          <WeatherMiniWidget />
        </Hidden>
        <DisconnectButton />
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: appBarHeight,
    zIndex: theme.zIndex.drawer + 1
  },
  title: {
    flexGrow: 2
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  }
}));
