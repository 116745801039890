import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';


export const Loading = ({ form = false }) => {

  const classes = useStyles();

  return (
    <div className={clsx(classes.container, !form && classes.paddingTop)}>
      <CircularProgress color='inherit' />
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paddingTop: {
    paddingTop: '50px'
  }
});
