import React, { useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Card, Grid, InputBase } from '@material-ui/core';

import { AppContext } from '../../context/AppContext';
import { Form } from '../common/Form';
import { PlanningContext } from '../../context/PlanningContext';
import { ages, levels, days } from '../../utils/filters';
import { appBarHeight } from '../../utils/layout';


export const Filters = () => {

  const { teachers } = useContext(AppContext);
  const { filters, updateFilters } = useContext(PlanningContext);
  const classes = useStyles();

  const handleChange = (name, value) => {
    updateFilters({
      ...filters,
      [name]: value,
    });
  };

  const ref = React.createRef()

  return (
    <div ref={ref} className={classes.filter}>
      <Card elevation={0} className={classes.paper}>
        <Grid container direction="column">
          <Grid item>
            <Form
              className={classes.filters}
              onChange={handleChange}
              fields={[
                {
                  name: 'teachers',
                  value: filters.teachers,
                  items: teachers.map((teacher) => ({
                    label: `${teacher.firstname} ${teacher.lastname}`,
                    value: teacher.id
                  })),
                  type: 'multipleSelect',
                  selectstyle: <BootstrapInput />
                },
                {
                  name: 'age',
                  value: filters.age,
                  items: ages,
                  type: 'select',
                  selectstyle: <BootstrapInput />
                },
                {
                  name: 'level',
                  value: filters.level,
                  items: levels,
                  type: 'select',
                  selectstyle: <BootstrapInput />
                },
                {
                  name: 'day',
                  value: filters.day,
                  items: days,
                  type: 'select',
                  selectstyle: <BootstrapInput />
                },
              ]}>
            </Form>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    zIndex: 1,
    borderBottom: '1px solid #dcdcdc',
    marginBottom: theme.spacing(2),
    backgroundColor: 'white',
    color: theme.palette.common.black,
    [theme.breakpoints.down('md')]: {
      left: 0,
      width: '100%',
    },
  },
  filter: {
    position: 'sticky',
    top: appBarHeight,
    zIndex: 1,
  },
  filters: {
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    padding: '5px 0 20px 20px',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(1, 3),
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid #ced4da',
    padding: '10px 26px 10px 12px',
    marginRight: 10,
    width: '10rem',
    '&:focus': {
      borderRadius: 4,
    },
  },
}))(InputBase);
