import React, { useState } from 'react';

import { LessonSlotData } from './LessonSlotData';
import { Loading } from '../common/Loading';
import { LessonSlotAdmin } from './LessonSlotAdmin';
import { LessonSlotBooking } from './LessonSlotBooking';


export const LessonSlot = ({ lessonSlot, onClose }) => {

  const [isBooking, setIsBooking] = useState(false);

  if (!lessonSlot) {
    return <Loading />;
  }

  let Screen = isBooking ? LessonSlotBooking : LessonSlotData;

  return (
    <>
      <Screen
        lessonSlot={lessonSlot}
        onClose={onClose}
        setIsBooking={setIsBooking} />
      <LessonSlotAdmin lessonSlot={lessonSlot} onClose={onClose} />
    </>
  );
};
