import React from 'react';
import MaskedInput from 'react-text-mask';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Checkbox,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import CreditCardPreview from './CreditCardPreview';
import { AppContext } from '../../context/AppContext';
import { payboxGetBrand } from '../../utils/gqlQueries';

/* */
const securityImg = 'https://static.prima.golf/images/payment/up2pay.png';

/* */
const styles = theme => ({
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
  securityText: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  marginT: {
    marginTop: theme.spacing(1),
  },
  checkboxCtrl: {
    alignItems: 'flex-start',
  },
});

/* */
const DueDateMaskedInput = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/]}
    />
  );
};

/* */
/* eslint-disable indent */
const getBrandName = (code) => {
  let name;
  switch (code) {
    case '1':
    case 'A':
    case 'B':
    case 'C':
    case 'D':
    case 'E':
      name = 'CB';
      break;
    case '2':
    case '8':
    case '9':
      name = 'VISA';
      break;
    case '0':
    case '3':
      name = 'MASTERCARD';
      break;
    default:
      name = null;
  }
  return name;
};
/* eslint-enable indent */

const initialState = {
  type: null,
  number: '',
  brand: null,
  dueDate: '',
  waiting: false,
  isValid: false,
  saveImprint: false,
};

/* */
class CompleteCreditCardForm extends React.Component {
  /* */
  static defaultProps = {
    imprintUse: 'disabled',
  }

  static contextType = AppContext;

  /* */
  state = { ...initialState };

  extraInfos = {};

  /* */
  isValid() {
    const { imprintUse } = this.props;
    const { saveImprint, isValid } = this.state;

    return (imprintUse === 'required') ? (saveImprint && isValid) : isValid;
  }

  /* */
  reset() {
    this.setState(initialState);
  }

  /* */
  getExtraInfos() {
    const { saveImprint } = this.state;

    return {
      saveImprint,
      ...this.extraInfos,
    };
  }

  /* */
  getBrand(number) {
    if (number.length < 10) return;

    this.setState({
      brand: null,
      waiting: true,
      isValid: false,
    });
    this.props.setFieldError('number', null);

    const { fetchApi } = this.context;

    fetchApi(payboxGetBrand, { number })
      .then(({ data: { payboxGetBrand } }) => {
        const brand = getBrandName(payboxGetBrand.MARQUE);
        this.setState({
          brand,
          isValid: true,
        });
        this.extraInfos = {
          brand,
          type: payboxGetBrand.PRODUIT,
          country: payboxGetBrand.PAYS,
        };
      })
      .catch(() => this.props.setFieldError('number', 'Numéro de carte inconnu'))
      .finally(() => this.setState({ waiting: false }));
  }

  /* */
  handleDateInputChange = (e) => {
    this.props.handleChange(e);
    this.setState({ dueDate: e.target.value });
  }

  /* */
  handleNumberInputChange = (e) => {
    this.props.handleChange(e);
    this.setState({ number: e.target.value });
  }

  /* */
  handleNumberInputBlur = (e) => {
    this.props.handleBlur(e);
    this.getBrand(e.target.value);
  }

  /* */
  render() {
    const {
      errors,
      values,
      classes,
      imprintUse,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
      submitButtonText,
      submitButtonHintText,
    } = this.props;

    const {
      brand,
      number,
      dueDate,
      waiting,
      saveImprint,
    } = this.state;

    return (
      <React.Fragment>
        <div className={classes.cardContainer}>
          <CreditCardPreview
            brand={brand}
            number={number}
            dueDate={dueDate} />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="number"
              variant="outlined"
              value={values.number}
              label="Numéro de carte"
              error={!!(errors.number)}
              onBlur={this.handleNumberInputBlur}
              onChange={this.handleNumberInputChange}
              helperText={
                waiting ? 'Vérification du numéro...' : errors.number
              }
              InputProps={{
                endAdornment:
                  waiting ? (
                    <InputAdornment position="end">
                      <CircularProgress color="inherit" size={16} />
                    </InputAdornment>
                  ) : null,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              required
              fullWidth
              name="dueDate"
              variant="outlined"
              onBlur={handleBlur}
              label="Expire à fin"
              value={values.dueDate}
              error={!!(errors.dueDate)}
              onChange={this.handleDateInputChange}
              InputProps={{
                inputComponent: DueDateMaskedInput,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              required
              fullWidth
              name="cvv"
              label="CVV"
              value={values.cvv}
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              error={!!(errors.cvv)}
              helperText="3 derniers chiffres présents au dos"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {imprintUse !== 'disabled' && (
            <Grid item xs={12}>
              <FormControlLabel
                className={classes.checkboxCtrl}
                control={
                  <Checkbox
                    value="1"
                    name="saveImprint"
                    color="secondary"
                    checked={saveImprint}
                    onChange={e => this.setState({ saveImprint: e.target.checked })} />
                }
                label="J'autorise que mes coordonnées bancaires soient sauvegardées sur la plateforme sécurisée de notre banque partenaire."
              />
            </Grid>
          )}
        </Grid>
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          className={classes.submitButton}
          disabled={isSubmitting || !this.isValid()}>
          {isSubmitting ? <CircularProgress color="inherit" size={24} /> : submitButtonText}
        </Button>
        {submitButtonHintText && (
          <Typography
            component="p"
            align="center"
            variant="caption"
            className={classes.marginT}>
            {submitButtonHintText}
          </Typography>
        )}
        <div className={classes.securityText}>
          <Typography component="span">
            {'Sécurisé par'}
          </Typography>
          <img alt="CA E-transactions" height={82} src={securityImg} />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CompleteCreditCardForm);
