import React, { useContext, useEffect, useState } from 'react';
import { Grid, IconButton, List, Typography, Divider, Button, Tooltip } from '@material-ui/core';
// Dependency for Draggable plugin
// eslint-disable-next-line no-unused-vars
import FullCalendar from '@fullcalendar/react';
import { Draggable } from '@fullcalendar/interaction';
import { Add as AddIcon, Info } from '@material-ui/icons';

import { LessonsItem } from './LessonsItem';
import { PlanningContext } from '../../context/PlanningContext.js';
import { Loading } from '../common/Loading';
import { Form } from '../common/Form';
import { AppContext } from '../../context/AppContext';
import moment from 'moment';


const draggable = {};
const setDraggable = (r) => {
  // console.log('setting draggable:', draggable, r);
  if (draggable[r]) draggable[r].destroy();
  draggable[r] = new Draggable(document.getElementById(`external-events${r ? '-recurrent' : ''}`), {
    itemSelector: `.draggable${r ? '-recurrent' : ''}`,
    eventData: ({ dataset }) => ({
      title: dataset.title,
      lessonId: dataset.id,
    }),
  });
};

const tooltipText = 'Pour ajouter un cours dans votre agenda, veuillez glisser-déposer un cours vers un slot horaire du calendrier.'

export const Lessons = ({ lessons, setLessons, recurrent, valid }) => {
  const {
    setLesson,
    newLesson,
    getLessons,
    lessonSlots,
  } = useContext(PlanningContext);

  const {
    teachers
  } = useContext(AppContext);
  const [filters, setFilters] = useState({
    teachers: -1,
  });


  useEffect(async () => {
    const l = await getLessons(recurrent ? 1 : 0);
    setLessons(l);
  }, [lessonSlots])

  useEffect(async () => {
    const l = await getLessons(recurrent ? 1 : 0);
    setLessons(l);
  }, []);

  useEffect(() => {
    if (lessons && lessons.length) {
      // console.log('haslessons');
      setDraggable(recurrent ? true : false)
    };
  }, [lessons]);

  if (!lessons) {
    return <Loading />;
  }
  const handleChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  return (
    <div id={`external-events${recurrent ? '-recurrent' : ''}`}>
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Grid item>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="row" justify="center" alignItems="center">
                <Typography variant="h6">Cours {recurrent ? 'récurrents' : 'ponctuels'}</Typography>
                <Tooltip title={tooltipText} placement="top" arrow>
                  <IconButton aria-label="delete">
                    <Info fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" size="small" startIcon={<AddIcon />} onClick={() => newLesson(recurrent)}>
                Ajouter un cours
              </Button>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" style={{ width: '100%', marginBottom: 8 }}>
            <Grid item>
              <Form
                onChange={handleChange}
                fields={[
                  {
                    name: 'teachers',
                    value: filters.teachers,
                    items: [{ value: -1, label: 'Tous les professeurs' }]
                      .concat(teachers.map((teacher) => ({
                        label: `${teacher?.firstname} ${teacher?.lastname}`,
                        value: teacher?.id
                      }))),
                    type: 'select',
                  },
                ]}>
              </Form>
            </Grid>
          </Grid>
          <List dense disablePadding style={{ maxHeight: 'calc(100vh - 375px)', height: 'calc(100vh - 375px)', overflowX: 'hidden', overflowY: 'scroll', borderBottom: 'none', borderRadius: 10, paddingRight: '8px' }}>
            {lessons.length !== 0 && lessons.filter((lesson) => filters.teachers === -1 || (lesson.teacherId === filters.teachers)).reduce((acc, sum, ind) => {
              acc[ind] = {
                ...sum,
                valid: !!lessonSlots?.filter(ls => ls.id === sum.slot.id)[0]?.valid || false
              }
              return acc;
            }, []).sort(function(a,b){
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return moment(moment(a.slot.date, 'x').format('d') + '-' + a.slot.startHour, 'd-HH:mm:ss') - moment(moment(b.slot.date, 'x').format('d') + '-' + b.slot.startHour, 'd-HH:mm:ss');
            }).sort((a, b) => {
              return a.valid - b.valid
            }).map((lesson, i) => (
              <LessonsItem onClick={() => setLesson(lesson)} key={i} {...lesson} />
            ))}
          </List>
          <Divider />
        </Grid>
      </Grid>
    </div>
  );
};
