import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';

import { getWeatherIcon } from '../../utils/weather';


export const WeatherMain = ({ weather }) => {

  const classes = useStyles();
  const src = getWeatherIcon(weather.weather[0].icon);

  return (
    <Grid container direction="row" justify="space-around" alignItems="center" className={classes.top}>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <img alt="Wheater Icon" src={src} className={classes.weatherIcon} />

          <Typography variant="body1" component="div">
            <Box fontWeight="fontWeightMedium">{weather.weather[0].description}</Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography component="div" variant="h4">
          <Box fontWeight="fontWeightBold">
            {~~weather.temp}°
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  weatherIcon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    shadowColor: theme.palette.common.black,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 5,
  },
}));
