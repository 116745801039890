import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from '../../context/AppContext';
import { Typography, Grid, Button } from '@material-ui/core';
import { getWeatherIcon } from '../../utils/weather';
import { GameConditionPopover } from './GameConditionPopover';


export const WeatherMiniWidget = () => {
  const { weather } = useContext(AppContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (!weather) return null;

  const src = getWeatherIcon(weather.icon)

  return (
    <>
      <Button onClick={handleClick} aria-describedby={id} aria-owns={id} aria-haspopup="true">
        <Grid container direction="row" justify="flex-end" alignItems="center" className={classes.weather}>
          <img alt="Wheater Icon" src={src} className={classes.icon} />
          <Typography variant="h6">{~~weather.temp} °C</Typography>
        </Grid>
      </Button>
      <GameConditionPopover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  weather: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white
  },
  icon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    shadowColor: theme.palette.common.black,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 5,
  }
}));
