export const ages = [
  { value: -1, label: 'Tous les âges' },
  { value: 'baby', label: 'Poussin (4-6 ans)' },
  { value: 'kid1', label: 'Jeune (6-9 ans)' },
  { value: 'kid2', label: 'Jeune (10-13 ans)' },
  { value: 'kid3', label: 'Jeune (14-17 ans)' },
  // { value: 'kid4', label: 'Enfant (13-17 ans)' },
  { value: 'teenager1', label: 'Espoir (-13 ans)' },
  { value: 'teenager2', label: 'Espoir (+13 ans)' },
  { value: 'adult', label: 'Adulte (18 ans et +)' }
];

export const genders = [
  { value: -1, label: 'Tous les genres' },
  { value: 'male', label: 'Homme' },
  { value: 'female', label: 'Femme' }
];

export const levels = [
  { value: -1, label: 'Tous les niveaux' },
  { value: 'all', label: 'Tout niveau' },
  { value: 'beginner', label: 'Débutant' },
  { value: 'advanced', label: 'Espoir' },
  { value: 'expert', label: 'Confirmé' },
];

export const recurrencies = [
  { value: -1, label: 'Toutes les périodes' },
  { value: 0, label: 'Occasionnel' },
  { value: 1, label: 'Récurrent' }
];

export const days = [
  { value: -1, label: 'Tous les jours' },
  { value: 1, label: 'Lundi' },
  { value: 2, label: 'Mardi' },
  { value: 3, label: 'Mercredi' },
  { value: 4, label: 'Jeudi' },
  { value: 5, label: 'Vendredi' },
  { value: 6, label: 'Samedi' },
  { value: 0, label: 'Dimanche' },
];

export const prepareFilters = (_filters, noDate) => {
  const filters = { ..._filters };

  if (noDate) {
    delete filters.from;
    delete filters.to;
  }

  Object.keys(filters)
    .forEach((key) => {
      if (
        filters[key] === -1 ||
        (Array.isArray(filters[key]) && filters[key].length <= 0)
      ) {
        delete filters[key];
      }
    });
  return filters;
};
