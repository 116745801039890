import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';

import { Admin } from './Admin';
import { NavMenu } from './NavMenu';
import { ProfilePaper } from './ProfilePaper';
import { adminRoutes, restrictedRoutes } from '../../routes';


export const SidebarContent = () => {

  const classes = useStyles();

  return (
    <>
      <ProfilePaper />
      <Admin hidden>
        <NavMenu routes={restrictedRoutes} />
        <Divider />
      </Admin>

      <Admin>
        <Typography variant="h6" className={classes.listHeader}>Administration</Typography>
        <NavMenu routes={adminRoutes} />
      </Admin>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  listHeader: {
    margin: theme.spacing(2, 0, 0, 2),
  },
}));
