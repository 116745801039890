import * as yup from 'yup';

/* */
export const createValidationSchema = validators => yup.object().shape(validators);

const PASSWORD_SECURITY = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;

/* */
function equalTo(ref, msg) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg, // || `${path} must be the same as ${reference}`,
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
}
yup.addMethod(yup.string, 'equalTo', equalTo);

/* */
export const validators = {

  require: validator => validator.required('Obligatoire'),
  /* */
  email: yup
    .string()
    .trim()
    .email('Adresse mail invalide.')
    .required('Obligatoire'),
  /* */
  emailConfirm: yup
    .string()
    .email('Adresse mail invalide.')
    .equalTo(
      yup.ref('email'),
      'Les emails ne correspondent pas.',
    ),
  /* */
  password: yup
    .string()
    .trim()
    .min(7, 'Doit contenir minimum 7 caractères.')
    .matches(PASSWORD_SECURITY, 'Au moins 1 chiffre, 1 majuscule et 1 minuscule.')
    .required('Obligatoire'),
  /* */
  passwordConfirm: yup
    .string()
    .equalTo(
      yup.ref('password'),
      'Les mots de passe ne correspondent pas.',
    ),
  /* */
  dateFourDigits: yup
    .string()
    .matches(/^([0-9]{2}\/[0-9]{2})$/, {
      message: ' ',
      excludeEmptyString: true,
    })
    .required(),
  /* */
  cvv: yup
    .string()
    .matches(/^([0-9]{3})$/, {
      message: ' ',
      excludeEmptyString: true,
    })
    .required(),
  /* */
  optional: yup
    .string()
    .trim()
    .typeError('Format incorrect'),
  /* */
  get required() {
    return this.optional
      .required('Obligatoire');
  },
};
