import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Popover, Typography, Grid, Divider } from '@material-ui/core'

import { AppContext } from '../../context/AppContext';
import { gameConditions, getTypes } from '../../utils/gameConditions';


export const GameConditionPopover = ({ id, open, anchorEl, onClose }) => {

  const { golf } = useContext(AppContext);
  const theme = useTheme();
  const conditions = gameConditions(golf)
  const types = getTypes(conditions)
  const classes = useStyles()

  return (
    <Popover id={id} open={open} anchorEl={anchorEl} onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      disableRestoreFocus>
      <div className={classes.content}>
        <Typography variant='h6'>{golf.name}</Typography>

        {types && types.length > 0
          && <Divider className={classes.separator} />}

        {types && types.map((type, j) => (
          <div key={j}>
            {conditions.map((condition, i) => {
              return condition.hasOwnProperty('label') && condition.type === type ?
                <Grid container direction="row" justify="space-between" alignItems="center" key={i}>
                  <Typography>{condition.label} {condition.name}</Typography>
                  <Typography className={classes.status} style={{ color: condition.status ? theme.palette.success.main : theme.palette.error.main }}>{condition.statuses[condition.status].label}</Typography>
                </Grid>
                : null
            })}
            {j < (types.length - 2) && <Divider className={classes.separator} />}
          </div>

        ))}
      </div>
    </Popover>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2)
  },
  status: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
  },
  separator: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));
