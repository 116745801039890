import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FieldSliderRange } from './FieldSliderRange';
import { FieldToggleButton } from './FieldToggleButton';


export const Field = ({ label, name, onChange, onCustomChange, selectstyle, fieldType = 'text', required = false, items = [], ...props }) => {

  const classes = useStyles();

  const handleChange = e => {
    const value = e.target.value;
    if (['range', 'number'].includes(fieldType))
      return onChange(name, +value);
    onChange(name, value);
  };

  let comp;
  /* eslint-disable indent */
  switch (fieldType) {
    case 'toggle':
      comp = (
        <FieldToggleButton
          label={label}
          required={required}
          onChange={onChange}
          name={name}
          {...props}
        />
      ); break;

    case 'range':
      comp = (
        <FieldSliderRange
          label={label}
          name={name}
          onChange={(_, [min, max]) => {
            onChange({
              [`${name}Min`]: min,
              [`${name}Max`]: max,
            })
          }}
          {...props}
        />); break;

    case 'select':
      comp = (
        <>
          {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
          <Select
            required
            label={label}
            defaultValue={props.value}
            value={props.value}
            onChange={handleChange}
            input={selectstyle}
            style={{ textAlign: 'left' }}
            {...props}>
            {items.map(item => (
              <MenuItem
                selected={(item.value ?? item) === props.value}
                value={item.value ?? item}
                key={item.label ?? item}>
                {item.label ?? item}
              </MenuItem>
            ))}
          </Select>
        </>
      ); break;

    case 'multipleSelect':
      comp = (
        <>
          {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
          <Select
            multiple
            displayEmpty
            value={props.value}
            onChange={handleChange}
            input={selectstyle}
            renderValue={(selected) => {
              if (selected.length === 0) return 'Tous les professeurs'
              return items.filter(item => selected.includes(item.value)).map(item => item.label).join(', ');
            }}
            {...props}>
            {items.map(item => (
              <MenuItem
                value={item.value}
                key={item.label}
              >
                <Checkbox checked={props.value.indexOf(item.value) > -1} />
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </>
      ); break;

    case 'number':
      comp = <TextField
        variant="outlined"
        required={required}
        name={name}
        label={label}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        onChange={handleChange}
        {...props}
      />; break;

    case 'date':
      comp = <TextField
        variant="outlined"
        required={required}
        name={name}
        label={label}
        onChange={onCustomChange || handleChange}
        placeholder="jj/mm/aaaa"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          pattern: '[0-9]{2}/[0-9]{2}/[0-9]{4}',
        }}
        {...props}
      />; break;

    case 'time':
      comp = <TextField
        variant="outlined"
        required={required}
        name={name}
        label={label}
        onChange={onCustomChange || handleChange}
        placeholder="hh:mm"
        inputProps={{
          pattern: '[0-9]{2}:[0-9]{2}',
        }}
        {...props}
      />; break;

    default:
      comp = <TextField
        variant="outlined"
        required={required}
        name={name}
        label={label}
        type={fieldType}
        onChange={handleChange}
        {...props}
      />;
  }
  /* eslint-enable indent */

  return (
    <FormControl variant="outlined" required={required} className={classes.formControl}>
      {comp}
    </FormControl>
  );
}

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: '15px'
  },
}));
