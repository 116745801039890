import { Grid, Typography, Box } from '@material-ui/core';


export const WeatherInformationItem = ({ title, data }) => (
  <Grid container direction="row" justify="center" alignItems="center">
    <Typography variant="body1" component="div">
      <Box fontWeight="fontWeightMedium">
        {title}
      </Box>
    </Typography>
    <Typography variant="body1" component="div" style={{ color: '#AFC8FB', paddingLeft: 20 }}>
      <Box fontWeight="fontWeightBold">
        {data}
      </Box>
    </Typography>
  </Grid>
);
