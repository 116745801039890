/**
 * GQL queries
 */

export const queryGetGolf = `
query ($id: String!) {
  getGolf(id: $id) {
    id
    name
    city
    postalCode
    email
    phone
    slug
    latitude
    longitude
    reference
    options {
      name
      value
    }
  }
}`;

export const queryGetAccount = `
query (
  $golfId: String!
) {
  getAccountEdg(
    golfId: $golfId
  ) {
    id
    firstname
    lastname
    phone
    address
    address2
    postalCode
    city
    birthdate
    gender
    email
    covidCertificate
    isAdminEdg
    ffg {
      license
      handicap
      handicapDate
    }
    matches {
      golfId
      reference
      isMember
      isFavorite
    }
    documents {
      id
      filename
      createdAt
    }
  }
}`;

export const queryGetAccountByToken = `
query (
  $golfId: String!
  $token: String!
) {
  getAccountEdgByToken(
    golfId: $golfId
    token: $token
  ) {
    id
    firstname
    lastname
    phone
    address
    address2
    postalCode
    city
    birthdate
    gender
    email
    phone
    covidCertificate
    token
    isAdminEdg
    ffg {
      license
      handicap
      handicapDate
    }
    matches {
      golfId
      reference
      isMember
      isFavorite
    }
    documents {
      id
      filename
      createdAt
    }
  }
}`;

export const querySchoolTeachers = `
query ($golfId: String!) {
  getSchoolTeachers(golfId: $golfId) {
    id,
    lastname,
    firstname,
    email,
    phone
  }
}`;

export const querySchoolLessons = `
query (
  $golfId: String!
  $teachers: [String!],
  $age: String,
  $day: Int
  $level: String,
  $recurrent: Int,
  ) {
  getSchoolLessons(
    golfId: $golfId,
    teachers: $teachers,
    age: $age,
    day: $day
    level: $level,
    recurrent: $recurrent
    ) {
      id,
      title,
      teacherId,
      teacher,
      price,
      displayPrice,
      age,
      day,
      places,
      studentsCount,
      description,
      recurrent,
      level,
      nextSlot
      pgbNumcotis
      slot {
        id
        date
        endHour
        startHour
      }
  }
}`;

export const querySchoolLessonSlot = `
query ($id: String!) {
  getSchoolLessonSlot(id: $id) {
    id,
    lessonId,
    lesson,
    teacherId,
    teacher,
    date,
    startHour,
    endHour,
    places,
    studentsCount,
    golfId,
    recurrent,
    valid,
    price,
    displayPrice,
    level,
    status
  }
}`;

export const querySchoolLessonSlots = `
query (
  $golfId: String!
  $from: String!
  $to: String!
  $teachers: [String!],
  $age: String,
  $level: String,
  $recurrent: Int,
  $all: Boolean
) {
    getSchoolLessonSlots(
      golfId: $golfId,
      from: $from,
      to: $to,
      teachers: $teachers,
      age: $age,
      level: $level,
      recurrent: $recurrent
      all: $all
    ) {
        id,
        lesson,
        date,
        startHour,
        endHour,
        valid,
        recurrent
    }
}`;

export const queryLessonSlotStudents = `
query (
  $ids: [String]
) {
    getSchoolLessonSlotStudents(
      ids: $ids
    ) {
      id
      lessonSlotId
      salesOrderLineId
      firstLessonSlotId
      comment
      lastname
      firstname
    }
}`;

export const queryGetSettings = `
query (
  $golfId: String!
) {
  getEDGSettings(
    golfId: $golfId
    ) {
      booking
    }
}`;

export const querySaveSettings = `
mutation (
  $golfId: String!
  $settings: Settings
) {
  saveEDGSettings(
    golfId: $golfId
    settings: $settings
    ) {
      booking
    }
}`;

export const queryGetUserLockStatus = `
query (
  $id: String!
) {
  getUserLockStatus(
      id: $id
    ) {
      expireAt
    }
}`;

export const queryLockPlace = `
mutation (
  $id: String!
) {
  lockPlace(
      id: $id
    ) {
      success
    }
}`;

export const queryUnlockPlace = `
mutation (
  $id: String!
) {
  unlockPlace(
      id: $id
    ) {
      success
    }
}`;

export const mutateLessonSlotStudents = `
mutation (
  $id: String
  $salesOrderLineSchoolIds: [String!]
) {
    transferLessonSlotStudents(
      id: $id
      salesOrderLineSchoolIds: $salesOrderLineSchoolIds
    ) {
      success
    }
}`;

export const querySchoolBookings = `
query (
  $golfId: String!
) {
    getSchoolBookings(
      golfId: $golfId
    ) {
      id,
      lessonSlotId,
      accountId,
      name,
      comment,
      lesson,
      recurrent,
      date,
      startHour,
      endHour,
      valid
    }
}`;

export const queryAccountCreditCards = `
query (
  $accountId: String!
) {
  getAccountCreditCards (
    accountId: $accountId
  ) {
    id,
    name,
    type,
    brand,
    token,
    number,
    country,
    dueDate
  }
}`;

export const queryWeather = `
query ($id: String!) {
  getGolfWeather(id: $id) {
    temp,
    min,
    max,
    humidity,
    wind,
    direction,
    main,
    weather,
    icon
  }
}`;

export const queryRecipientViewUrl = `
query (
  $golfId: String!
  $callbackUrl: String!
) {
  getRecipientViewUrl (
    golfId: $golfId,
    callbackUrl: $callbackUrl
  ) {
    viewUrl
  }
}`;

export const queryGetNumcotisList = `
query (
  $golfId: String!
) {
  getNumcotisList (
    golfId: $golfId,
  ) {
    pgbCode
    name
  }
}`;

export const verifySignatureFromAccountAndGolf = `
query (
  $golfId: String!
) {
  verifySignatureFromAccountAndGolf (
    golfId: $golfId
  ) {
    signed
  }
}
`;

export const mutateLesson = isModif => isModif
  ? `mutation (
      $id: String!
      $title: String
      $teacherId: String
      $price: Float
      $teachPrice: Float
      $age: String
      $day: String
      $description: String
      $places: Int
      $level: String
      $pgbNumcotis: String
    ) {
      updateLesson (
        id: $id,
        title: $title,
        teacherId: $teacherId,
        price: $price,
        teachPrice: $teachPrice,
        age: $age,
        day: $day,
        description: $description,
        places: $places,
        level: $level
        pgbNumcotis: $pgbNumcotis
      ){ id }
    }`
  : `mutation (
      $teacherId: String!
      $title: String!
      $price: Float!
      $teachPrice: Float
      $age: String!
      $day: String
      $description: String!
      $recurrent: Boolean!
      $places: Int!
      $level: String!
      $pgbNumcotis: String!
    ) {
      createLesson (
        teacherId: $teacherId,
        title: $title,
        price: $price,
        teachPrice: $teachPrice,
        age: $age,
        day: $day
        description: $description,
        recurrent: $recurrent,
        places: $places,
        level: $level
        pgbNumcotis: $pgbNumcotis
      ) { id }
    }`;

export const mutateLessonSlot = isModif => isModif
  ? `mutation (
      $id: String!
      $date: String
      $startHour: String
      $endHour: String
      $valid: Boolean
     ) {
      updateLessonSlot(
        id: $id,
        date: $date,
        startHour: $startHour,
        endHour: $endHour,
        valid: $valid
      ){ id }
    }`
  : `mutation (
      $lessonId: String!
      $date: String!
      $startHour: String!
      $endHour: String!
     ) {
        createLessonSlot(
          lessonId: $lessonId,
          date: $date,
          startHour: $startHour,
          endHour: $endHour
        ) { id }
    }`;

export const addDocument = `
    mutation (
      $file: Upload!
      $access: String
      $type: String
      $ownerId: String
      $readerId: String
    ) {
      addDocument(
        file: $file
        access: $access
        type: $type
        ownerId: $ownerId
        readerId: $readerId
      ) { id }
    }
`;
export const deleteLessonSlot = `
    mutation (
      $id: String!
    ) {
      deleteLessonSlot(
        id: $id
      ) { id }
    }
`;

export const mutateBooking = `
mutation (
  $lessonSlotId: String!
  $lastname: String!
  $firstname: String!
  $address: String
  $address2: String
  $postalCode: String
  $city: String
  $phone: String!
  $birthdate: String!
  $gender: String!
  $comment: String
  $reservationOther: Boolean
  $transaction: SchoolTransactionInput!
  ) {
  createSchoolBooking (
    lessonSlotId: $lessonSlotId,
    lastname: $lastname,
    firstname: $firstname,
    address: $address,
    address2: $address2,
    postalCode: $postalCode,
    city: $city,
    phone: $phone,
    birthdate: $birthdate,
    gender: $gender,
    comment: $comment,
    transaction: $transaction,
    reservationOther: $reservationOther,
  ) { id, status, message }
}`;

export const confirmSchoolBooking = `
mutation (
  $id: String!
  $lessonSlotId: String!
  $transaction: SchoolTransactionInput!
) {
  confirmSchoolBooking (
    id: $id
    lessonSlotId: $lessonSlotId
    transaction: $transaction
  ) { id }
}`;

export const mutateCaptureSubscriber = `
mutation (
  $amount: Int! ,
  $reference: String!,
  $subscriber: PayboxSubscriber
) {
  payboxCaptureSubscriber (
    amount: $amount,
    reference: $reference,
    subscriber: $subscriber
  ) {
    PORTEUR,
    NUMTRANS,
    NUMAPPEL,
    REFABONNE
  }
}`;

export const verifySignatureAndUpdateStatus = `
mutation ($id: String!) {
  verifySignatureAndUpdateStatus (id: $id) { signed }
}`;


export const payboxGetBrand = `
  query($number: String!) {
    payboxGetBrand(number: $number) {
      PAYS
      MARQUE
      PRODUIT
    }
  }
`;

export const payboxCapture = `
  mutation(
    $amount: Int!
    $reference: String!
    $creditCard: PayboxCreditCard!
    $id3D: String
  ) {
    payboxCapture(
      id3D: $id3D
      amount: $amount
      reference: $reference
      creditCard: $creditCard
    ) {
      NUMTRANS
      NUMAPPEL
    }
  }
`;

export const payboxCaptureSubscriber = `
  mutation(
    $amount: Int!
    $reference: String!
    $subscriber: PayboxSubscriber
  ) {
    payboxCaptureSubscriber(
      amount: $amount
      reference: $reference
      subscriber: $subscriber
    ) {
      NUMTRANS
      NUMAPPEL
    }
  }
`;

export const payboxSubscribe = `
  mutation(
    $amount: Int!
    $uuid: String!
    $reference: String!
    $creditCard: PayboxCreditCard!
    $id3D: String
  ) {
    payboxSubscribe(
      id3D: $id3D
      uuid: $uuid
      amount: $amount
      reference: $reference
      creditCard: $creditCard
    ) {
      REFABONNE
      PORTEUR
    }
  }
`;

export const payboxSubscribeAndCapture = `
  mutation(
    $amount: Int!
    $uuid: String!
    $reference: String!
    $creditCard: PayboxCreditCard!
    $id3D: String
  ) {
    payboxSubscribeAndCapture(
      id3D: $id3D
      uuid: $uuid
      amount: $amount
      reference: $reference
      creditCard: $creditCard
    ) {
      REFABONNE
      PORTEUR
      NUMTRANS
      NUMAPPEL
    }
  }
`;

export const getCreditCard = `
  query($id: String!) {
    getCreditCard(id: $id) {
      name
      type
      brand
      token
      number
      dueDate
      country
    }
  }
`;

export const getAccountCreditCards = `
  query($accountId: String!) {
    getAccountCreditCards(accountId: $accountId) {
      id
      name
      type
      brand
      token
      number
      dueDate
      country
    }
  }
`;

export const setAccountCreditCard = `
  mutation(
    $id: String!
    $name: String
    $type: String
    $brand: String
    $token: String!
    $number: String!
    $country: String
    $dueDate: String!
    $accountId: String!
  ) {
    setAccountCreditCard(
      id: $id
      name: $name
      type: $type
      brand: $brand
      token: $token
      number: $number
      dueDate: $dueDate
      country: $country
      accountId: $accountId
    ) {
      id
      name
      type
      brand
      token
      number
      dueDate
      country
    }
  }
`;
export const setAccountEdg = `
  mutation(
    $email: String!
    $password: String!
    $firstname: String!
    $lastname: String!
    $address: String
    $address2: String
    $postalCode: String
    $city: String
    $phone: String!
    $license: String
    $activationData: ActivationData
  ) {
    setAccountEdg(
      email: $email
      password: $password
      firstname: $firstname
      lastname: $lastname
      address: $address
      address2: $address2
      postalCode: $postalCode
      city: $city
      phone: $phone
      license: $license
      activationData: $activationData
    ) {
      id
      firstname
      lastname
      email
      address
      address2
      postalCode
      city
      phone
      ffg {
        license
        handicap
        handicapDate
      }
      matches {
        golfId
        reference
        isMember
        isFavorite
      }
    }
  }
`;