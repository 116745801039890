import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { makeStyles } from '@material-ui/core/styles';
import '../theme/theme.css'
import { Paper, FormControlLabel, Grid, Typography, Switch } from '@material-ui/core';
import { Layout } from './common/Layout';
import { queryGetSettings, querySaveSettings } from '../utils/gqlQueries';

/* */
const useStyles = makeStyles((theme) => ({
  column: {
    flexBasis: '25%',
    maxHeight: 'calc(100vh - 210px)',
    overflow: 'hidden',
    overflowY: 'auto',
    marginRight: theme.spacing(3),
  },
  setting: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '100%',
    flexDirection: 'row',
  },
  paper: {
    // width: '100%',
    overflow: 'auto',
    marginRight: theme.spacing(3),
  },
  spacer: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  label: {
    userSelect: 'none',
    MozUserSelect: 'none'
  },
}));

const defaultSettings = [
  {
    name: 'booking',
    label: 'Réservation en ligne',
    activeIndicator: 'Active',
    inactiveIndicator: 'Inactive',
    default: true
  },
];

export const AdminSettings = () => {
  const classes = useStyles();
  const { golf, fetchApi } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(defaultSettings.reduce((acc, setting) => ({ ...acc, [setting.name]: setting.default }), {}));
  const getSettings = (golfId) => fetchApi(queryGetSettings, {
    golfId,
  }).then(res => res?.data?.getEDGSettings)
  const saveSettings = (golfId, variables) => fetchApi(querySaveSettings, {
    golfId,
    settings: variables,
  }).then(res => res?.data?.saveEDGSettings)

  useEffect(() => {
    getSettings(golf.id).then(res => {
      if(res) {
        setSettings(res ? res : defaultSettings.reduce((acc, setting) => ({ ...acc, [setting.name]: setting.default }), {}));
      }
      setLoading(false);
    });
  }, [golf.id]);

  const handleChange = ({ target: { checked, name } }) => {
    setLoading(true);
    setSettings({ ...settings, [name]: checked });
  }

  useEffect(() => {
    if(settings) {
      saveSettings(golf.id, settings);
      setLoading(false);
    }
  }, [settings]);

  return (
    <Layout>
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.spacer}>
        <Typography variant="h4">Paramètres</Typography>
      </Grid>
      <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.spacer}>
        <Paper className={classes.column}>
          {defaultSettings.map((setting, i) => (
            <FormControlLabel
              key={`${setting.name}-${i}`}
              className={classes.setting}
              disabled={loading}
              control={
                <Switch
                  name={setting.name}
                  checked={settings[setting.name]}
                  onChange={handleChange}
                  className={classes.input}
                  color="primary"
                />
              }
              label={
                <Typography variant="body1" className={classes.label}>
                  {`${setting.label} (${settings[setting.name] ? setting.activeIndicator : setting.inactiveIndicator})`}
                </Typography>
              }
              labelPlacement="start"
            />
          ))}
        </Paper>
      </Grid>
    </Layout>
  );
}
