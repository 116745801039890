import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemIcon, ListItemText, ListItem } from '@material-ui/core';


export const NavLink = (route) => {

  const classes = useStyles();

  return (
    <Link to={route.url} className={classes.menuRoute}>
      <ListItem button key={route.name} >
        <ListItemIcon className={classes.icon}>
          {route.icon}
        </ListItemIcon>
        <ListItemText primary={route.name} />
      </ListItem>
    </Link>
  );
}

const useStyles = makeStyles((theme) => ({
  menuRoute: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  icon: {
    color: 'black',
    minWidth: '40px',
  }
}));
