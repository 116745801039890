import React, { useContext } from 'react';

import { Admin } from '../common/Admin';
import { ButtonBar } from '../common/ButtonBar';
import { mutateLessonSlot, deleteLessonSlot } from '../../utils/gqlQueries';
import { AppContext } from '../../context/AppContext';
import { PlanningContext } from '../../context/PlanningContext';


export const LessonSlotAdmin = ({ lessonSlot, onClose }) => {

  const { fetchApi } = useContext(AppContext);
  const { getLessonSlots } = useContext(PlanningContext);

  const validate = () => {
    fetchApi(
      mutateLessonSlot('updateLessonSlot'),
      {
        id: lessonSlot.id,
        valid: true
      })
      .then(() => getLessonSlots())
      .then(onClose);
  };

  const doDeleteLessonSlot = () => {
    fetchApi(
      deleteLessonSlot,
      {
        id: lessonSlot.id,
      })
      .then(() => getLessonSlots())
      .then(onClose);
  };

  return (
    <Admin>
      <ButtonBar
        justify="space-between"
        buttons={[{
          label: 'Supprimer ce créneau',
          color: 'secondary',
          disabled: lessonSlot.studentsCount > 0,
          onClick: doDeleteLessonSlot,
        }, {
          disabled: lessonSlot.valid,
          label: 'Valider ce créneau',
          variant: 'contained',
          onClick: validate,
        }]} />
    </Admin>
  );
};
