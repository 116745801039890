import React from 'react';
import { Typography, Slider } from '@material-ui/core';


export const FieldSliderRange = ({
  label,
  minMax,
  defaultValue,
  value,
  step,
  onChange,
}) => {

  const toString = (range) => {
    return `(entre ` + range[0] + ' et ' + range[1] + ')';
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <Typography id="range-slider" gutterBottom>
        {label} {toString(value)}
      </Typography>
      <Slider
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={toString}
        min={minMax[0]}
        max={minMax[1]}
        step={step}
      />
    </div>
  );
};
