import moment from 'moment';


export const createUuidFrom = (id) => `${id.slice(0, -12)}${moment().format('x').slice(-12)}`;

export const anonymizeNumber = (str) => {
  if (str.length > 0) {
    return str.replace(/(\d+)(\d{4})/g, (match, start, end) => `${'*'.repeat(start.length)}${end}`);
  }
  return str;
};

export const getPayboxError = (code) => {
  const msgs = {
    'PBX00001': 'La connexion au centre d\'autorisation a échoué.',
    'PBX00007': 'Date invalide.',
    'PBX00008': 'Date de fin de validité dépassée.',
    'PBX00020': 'Cryptogramme visuel non présent.',
    'PBX00021': 'Carte non autorisée.',
    'PBX00022': 'Plafond atteint.',
    'PBX00141': 'Carte perdue.',
    'PBX00143': 'Carte volée.',
    'PBX00151': 'Provision insuffisante ou crédit dépassé.',
    'PBX00154': 'Date de validité de la carte dépassée.',
  };
  const defaultMsg = 'Refus de paiement.';

  return `Une erreur est survenue : ${msgs[code] || defaultMsg}`;
};
