import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* */
const defaultCardBg = 'https://static.prima.golf/images/payment/card-bg.png';

/* */
const cbLogo = 'https://static.prima.golf/images/payment/cb.png';
const mcdLogo = 'https://static.prima.golf/images/payment/mcd.png';
const visaLogo = 'https://static.prima.golf/images/payment/visa.png';

/* */
const styles = theme => ({
  card: {
    width: '290px',
    height: '170px',
    // margin: '0 auto',
    position: 'relative',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: theme.palette.common.white,
    backgroundImage: `url(${defaultCardBg})`,
  },
  logo: {
    top: 6,
    right: 20,
    width: 50,
    height: 50,
    position: 'absolute',
  },
  text: {
    position: 'absolute',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.35)',
  },
  label: {
    top: 114,
    left: 88,
    width: 32,
    fontSize: 8,
    textAlign: 'right',
  },
  number1: { left: 48, top: 84 },
  number2: { left: 98, top: 84 },
  number3: { left: 148, top: 84 },
  number4: { left: 198, top: 84 },
  dueDate: { top: 116, left: 128 },
});

/* */
const Element = ({ text, ...props }) => (
  <Typography
    component="span"
    color="inherit"
    {...props}>
    {text.toUpperCase()}
  </Typography>
);

/* */
/* eslint-disable indent */
const getBrandImage = (name) => {
  let img;
  switch (name) {
    case 'CB':
      img = cbLogo;
      break;
    case 'VISA':
      img = visaLogo;
      break;
    case 'MASTERCARD':
      img = mcdLogo;
      break;
    default:
      img = null;
  }

  if (!img) return null;

  return img;
};
/* eslint-enable indent */


/* */
class CreditCardPreview extends React.PureComponent {
  /* */
  renderLogo() {
    const { classes, brand } = this.props;

    const img = getBrandImage(brand);

    return img ? <img alt={brand} className={classes.logo} src={img} /> : null;
  }

  /* */
  render() {
    const { classes, number } = this.props;

    const dueDate = this.props.dueDate.replace(/\D/g, '');
    const formattedDate = dueDate ? `${dueDate.substr(0, 2)}/${dueDate.substr(2, 2)}` : '';

    return (
      <div className={classes.card}>
        {this.renderLogo()}
        <Element
          text={number.slice(0, 4)}
          className={`${classes.number1} ${classes.text}`} />
        <Element
          text={number.slice(4, 8)}
          className={`${classes.number2} ${classes.text}`} />
        <Element
          text={number.slice(8, 12)}
          className={`${classes.number3} ${classes.text}`} />
        <Element
          text={number.slice(12, 16)}
          className={`${classes.number4} ${classes.text}`} />
        <Element
          text="Expire à fin"
          className={`${classes.label} ${classes.text}`} />
        <Element
          text={formattedDate}
          className={`${classes.dueDate} ${classes.text}`} />
      </div>
    );
  }
}

export default withStyles(styles)(CreditCardPreview);
