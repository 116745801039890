import moment from 'moment';
import 'moment/locale/fr';


export const formatToUS = (date) => {
  if (date.includes('/')) {
    const fr = moment(date, 'DD/MM/YYYY');
    if (fr.isValid()) return fr.format('YYYY-MM-DD');
  }

  const us = moment(date, 'YYYY-MM-DD');
  if (us.isValid()) return date;

  return false;
}

export const formatDate = (date, format) => moment(date)
  .format(format);

export const formatTime = time => {
  const hour = time.split(':');
  return hour[0] + 'H' + hour[1];
};

export const getDuration = (start, end, format = 'HH:mm') => {
  const formatDiff = 'HH:mm:ss';
  const duration = moment.duration(
    moment(end, formatDiff)
      .diff(moment(start, formatDiff))
  );
  return moment
    .utc(duration.as('milliseconds'))
    .format(format);
};

export const getDayOfWeek = (date) => {
  const dayAsNumber = moment(date)
    .day();
  const days = [
    'dimanche',
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi'
  ];
  return days[dayAsNumber];
};

export const translateDay = (day) => {
  /* eslint-disable indent */
  switch (day) {
    case 'monday':
      return 'Lundi';
    case 'tuesday':
      return 'Mardi';
    case 'wednesday':
      return 'Mercredi';
    case 'thursday':
      return 'Jeudi';
    case 'friday':
      return 'Vendredi';
    case 'saturday':
      return 'Samedi';
    case 'sunday':
      return 'Dimanche';
    default:
      return 'Lundi';
  }
  /* eslint-enable indent */
}
