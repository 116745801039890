import React from 'react';
import { ToggleButtonGroup, ToggleButton as MuiToggleButton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';


export const FieldToggleButton = ({ label, onChange, name, value, disabled, values }) => {

  const handeChange = (e, value) => {
    onChange(name, !!value);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography gutterBottom>
        {label}
      </Typography>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handeChange}
      >
        <MuiToggleButton value={false} disabled={disabled || values.off.disabled}>
          <Container icon={values.off.icon} name={values.off.label} />
        </MuiToggleButton>
        <MuiToggleButton value={true} disabled={disabled || values.on.disabled}>
          <Container icon={values.on.icon} name={values.on.label} />
        </MuiToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

const Container = ({ name, icon }) => {
  return (
    <div style={{ width: 150 }}>
      <Grid container direction="column" justify="center" alignItems="center" >
        <Grid item >{icon}</Grid>
        <Grid item >{name}</Grid>
      </Grid>
    </div>
  );
}
