import React, { useContext } from 'react';

import { PlanningContext } from '../../context/PlanningContext';
import { Dialog } from '../common/Dialog';
import { LessonForm } from './LessonForm';


export const LessonDialog = ({ updateLessons }) => {

  const {
    getLessons,
    lesson,
    clearLesson
  } = useContext(PlanningContext);

  const isModif = () => lesson && !!lesson.id;

  const onSubmitLessonForm = async () => {
    updateLessons(await getLessons(lesson.recurrent ? 1 : 0), lesson.recurrent);
    clearLesson();
  };

  return (
    <Dialog
      onClose={clearLesson}
      open={!!lesson}
      title={isModif() ? 'Modifier un cours' : 'Créer un cours'}>

      <LessonForm
        lesson={lesson}
        onClose={clearLesson}
        onSubmit={onSubmitLessonForm} />

    </Dialog>
  );
};
