import React, { useEffect, useContext, useState } from 'react';
import {
  Grid,
  Paper,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  FormControlLabel,
  TableRow,
  Typography,
  CircularProgress,
  RadioGroup,
  Radio,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  Button,
  Modal,
} from '@material-ui/core';
import moment from 'moment';

import { AppContext } from '../context/AppContext';
import { PlanningContext } from '../context/PlanningContext';
import {
  mutateBooking,
  confirmSchoolBooking,
  payboxCapture,
  payboxCaptureSubscriber,
  payboxSubscribeAndCapture,
  queryAccountCreditCards,
  setAccountCreditCard,
  // verifySignatureAndUpdateStatus,
  // verifySignatureFromAccountAndGolf,
} from '../utils/gqlQueries';
import {
  anonymizeNumber,
  createUuidFrom,
  getPayboxError,
} from '../utils/helpers/creditCard';
import { Layout } from './common/Layout';
import { formatDate, formatTime, getDayOfWeek } from '../utils/date';
import { PageNotFound } from './PageNotFound';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import Payment3DS from './3ds/Payment3DS';
import { filterByKeys } from '../utils/helpers/object';
import Alert from './common/Alert';
import { levels } from '../utils/filters';


const CGV = 'https://storage.googleapis.com/primagolf-app/CGV%20-%20ECOLE%20DE%20GOLF%202024-2025.pdf'

export const Payment = () => {
  const classes = useStyles();

  const { user, golf, fetchApi } = useContext(AppContext);
  const { lessonSlot, getLessonSlot, clearLessonSlot, unLockLessonPlace, getUserLockStatus } = useContext(PlanningContext);

  const [booking, setBooking] = useState(null);

  const [captureData, setCaptureData] = useState({});
  const [accountCreditCards, setAccountCreditCards] = useState([]);
  const [expirationTime, setExpirationTime] = useState(null);
  const [startingDate] = useState(moment());
  const [paymentType, setPaymentType] = useState('cash');
  const [timeLeft, setTimeLeft] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [warn, setWarn] = useState(null);
  const [infoLevel, setInfoLevel] = useState('info');
  const [success, setSuccess] = useState(null);
  const [errorPbx, setErrorPbx] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [documentValidation, setDocumentValidation] = useState(false);

  const nextPaymentDate = moment(startingDate).date(5).add(2, 'months');
  const finalPaymentDate = moment(startingDate).date(5).add(4, 'months');

  // build session 3DS
  const session3DS = {
    address: {
      city: golf.city,
      countryCode: 250, // golf.country
      line1: golf.name,
      zipCode: golf.postalCode,
    },
    customer: {
      email: user.email,
      firstName: user.firstname,
      lastName: user.lastname,
    },
  };

  // URL params
  const urlParams = new URLSearchParams(window.location.search);
  const lessonSlotId = urlParams.get('lsId');
  let timeDiff = null;
  // const envelopeId = urlParams.get('dsId');
  const event = urlParams.get('event');

  const history = useHistory();

  const urlBookings = `/${golf.slug}/bookings`;

  const clearBooking = () => sessionStorage.removeItem('school_booking');

  const getAccountCreditCards = () => {
    fetchApi(queryAccountCreditCards, { accountId: user.id })
      .then((response) => {
        setLoading(false);
        if (!response || !response.data) return;
        setAccountCreditCards(response.data.getAccountCreditCards);
      });
  };

  const scrollToError = () => {
    setTimeout(() => {
      const element = document.getElementById('error-anchor');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 250);
  }

  useEffect(() => {
    if (!documentValidation) {
      setModalIsOpen(true);
    }
  }, [])

  useEffect(() => {
    if (!timeLeft) return getUserLockStatus({ id: lessonSlotId }).then(res => {
      if (!res) {
        setInfoLevel('error')
        if (lessonSlot?.places - lessonSlot?.studentsCount !== 0) {
          setInfo(`Vous avez dépassé le temps imparti, votre place n'est plus garantie, actuellement, il reste ${lessonSlot?.places - lessonSlot?.studentsCount} place(s) libre(s) pour ce cours. (actualisation automatique).`)
        } else {
          setInfo(`Il n'y a plus de place disponible pour ce cours.`)
        }
        const check = setTimeout(async () => {
          getLessonSlot({ id: lessonSlotId })
        }, 60000)
        return  () => clearTimeout(check);
      }
      setTimeLeft(moment(res.expireAt))
      setExpirationTime(res.expireAt)
      return null;
    })
    if (-timeDiff >= 1000 || !timeDiff) {
      timeDiff = -timeLeft.diff(moment());
      const timer = setTimeout(async () => {
        if (-timeDiff >= 1000) {
          switch (true) {
          case -timeDiff >= 1000 && -timeDiff < 300000:
            setInfoLevel('error')
            break;
          case -timeDiff >= 300000 && -timeDiff < 600000:
            setInfoLevel('warning')
            break;
          default:
            setInfoLevel('info')
            break;
          }
          setInfo(`Une place vous est actuellement réservée, elle sera libérée ${timeLeft.fromNow()} si aucun paiement n'est effectué.`)
        } else {
          setInfoLevel('error')
          if (lessonSlot) {
            if (lessonSlot.places - lessonSlot.studentsCount !== 0) {
              unLockLessonPlace({ id: lessonSlotId })
              getLessonSlot({ id: lessonSlotId })
              setInfo(`Vous avez dépassé le temps imparti, votre place n'est plus garantie, actuellement, il reste ${lessonSlot.places - lessonSlot.studentsCount} place(s) libre(s) pour ce cours. (actualisation automatique).`)
            } else {
              setInfo(`Il n'y a plus de place disponible pour ce cours.`)
            }
          }
        }
        setTimeLeft(moment(expirationTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [timeLeft, lessonSlot])

  useEffect(() => {
    setLoading(false);
    // if (!envelopeId) {
    //   // User already signed the golf rules
    //   fetchApi(verifySignatureFromAccountAndGolf, { golfId: golf.id })
    //     .then((response) => {
    //       setLoading(false);
    //       if (!response || !response.data) return;
    //       if (!response.data.verifySignatureFromAccountAndGolf.signed) {
    //         return setError(`
    //           Le règlement intérieur n'a pas été signé.
    //           Il vous est impossible d'effectuer le paiement de l'inscription.
    //           Merci de recommencer le processus d'inscription.
    //         `);
    //       }
    //     });
    // } else {
    //   // User just signed through the Docusign process
    //   fetchApi(verifySignatureAndUpdateStatus, { id: envelopeId })
    //     .then((response) => {
    //       setLoading(false);
    //       if (!response || !response.data) return;
    //       /**
    //        * @todo: maybe we should call getBookingFromId to get the player doing the lesson?
    //        */
    //       if (!response.data.verifySignatureAndUpdateStatus.signed) {
    //         return setError(`
    //           Le règlement intérieur n'a pas été signé.
    //           Il vous est impossible d'effectuer le paiement de l'inscription.
    //           Merci de recommencer le processus d'inscription.
    //         `);
    //       }
    //     });
    // }
    // Get the lessonSlot from the URL to open the dialog
    setBooking(JSON.parse(sessionStorage.getItem('school_booking')));
    // Values about the order
    getLessonSlot({ id: lessonSlotId });
    // Get the account credit cards
    getAccountCreditCards();
    setWarn(`Si vous souhaitez payer par un autre autre moyen de paiement, veuillez vous rapprocher de l’accueil du golf pour valider votre inscription.`);
  }, []);

  useEffect(() => {
    if (lessonSlot && booking) {
      if (!booking.lessonSlotId || lessonSlot.id !== booking.lessonSlotId) {
        return setError(`
            Erreur lors du processus d'inscription.
            Merci de recommencer.
        `);
      }

      // Initialize the paybox state
      setCaptureData({
        amount: lessonSlot.price * 100,
        reference: `EDG-${user.id}`,
      });
      setSuccess(true);
    }

    // console.log(booking);

    if (lessonSlot && !booking) {
      return setError(`
        Erreur lors du processus d'inscription.
        Merci de recommencer.
      `);
    }
  }, [lessonSlot]);

  if (!lessonSlotId || (event !== 'signing_complete' && event !== 'signing_done')) {
    return <PageNotFound />;
  }

  /* */
  const bookSchoolLesson = (transaction) => {
    // Call confirmSchoolBooking endpoint
    return fetchApi(mutateBooking, { ...booking, transaction })
      .then((response) => {
        // if (response && response.errors) {
        //   setLoading(false);
        //   // return setError(response.errors[0].message);
        //   return setError(
        //     'Le paiement a été effectué mais une erreur de validation est survenue. Veuillez contacter votre golf.'
        //   );
        // }

        const { data } = response;
        if (!data) {
          return setError(
            '(0) Une erreur de validation est survenue. Veuillez contacter votre golf.'
          );
        }
        const { createSchoolBooking } = data;
        const { status } = createSchoolBooking;

        if (status) {
          if (status === 'failed') {
            return setError(
              '(1) Une erreur de validation est survenue. Veuillez contacter votre golf.'
            );
          }
          if (status === 'cancel.success') {
            return setError(
              '(2) Une erreur de validation est survenue. Veuillez contacter votre golf.'
            );
          }
          if (status === 'cancel.error') {
            return setError(
              '(3) Une erreur de validation est survenue. Veuillez contacter votre golf.'
            );
          }
          return setError(
            '(4) Une erreur de validation est survenue. Veuillez contacter votre golf.'
          );
        }

        const bookingId = response.data.createSchoolBooking.id;

        fetchApi(confirmSchoolBooking, {
          transaction,
          id: bookingId,
          lessonSlotId: lessonSlot.id,
        })
          .then((response) => {
            setLoading(false);
            if (!response || !response.data) {
              return setError(
                'Le paiement a été effectué mais une erreur de validation est survenue. Veuillez contacter votre golf.'
              );
            }
            unLockLessonPlace({ id: lessonSlot.id })
            clearLessonSlot();
            clearBooking();
            history.push(`${urlBookings}?success=true`);
          });
      }).catch(err => null);
  }

  /* */
  const capturePbx = (creditCard) => {
    const { id3D = null } = creditCard;
    const { amount, reference } = captureData;

    if (creditCard.saveImprint) {
      const uuid = createUuidFrom(user.id);

      return fetchApi(payboxSubscribeAndCapture, {
        uuid,
        id3D,
        amount,
        reference,
        creditCard: filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
      }).then(res => {
        if (res.errors) {
          throw new Error(getPayboxError(res.errors[0].message) || 'Erreur lors de la transaction');
        }

        return fetchApi(setAccountCreditCard, {
          id: uuid,
          accountId: user.id,
          token: res.data.payboxSubscribeAndCapture.PORTEUR,
          number: anonymizeNumber(creditCard.number),
          ...filterByKeys(creditCard, ['name', 'dueDate', 'type', 'brand', 'country']),
        }).then((res2) => {
          if (res2.errors) {
            throw new Error('Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.');
          }

          return {
            NUMAPPEL: res.data.payboxSubscribeAndCapture.NUMAPPEL,
            NUMTRANS: res.data.payboxSubscribeAndCapture.NUMTRANS,
          };
        });
      });
    }

    return fetchApi(payboxCapture, {
      id3D,
      amount,
      reference,
      creditCard: filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    }).then((res) => {
      if (res.errors) {
        // console.error(res.errors);
        throw new Error(getPayboxError(res.errors[0].message) || 'Erreur lors de la transaction');
      }

      return {
        NUMAPPEL: res.data.payboxCapture.NUMAPPEL,
        NUMTRANS: res.data.payboxCapture.NUMTRANS,
      };
    });
  }

  /* */
  const payWithCardImprint = (values) => {
    const [imprint] = accountCreditCards;
    const { amount, reference } = captureData;

    const subscriber = {
      id: imprint.id,
      token: imprint.token,
      dueDate: imprint.dueDate,
    };

    setErrorPbx(null);

    return fetchApi(payboxCaptureSubscriber, {
      amount,
      reference,
      subscriber,
    }).then(res => {
      if (res.errors) {
        // console.error(res.errors);
        throw new Error(getPayboxError(res.errors[0].message) || 'Erreur lors de la transaction');
      }

      const transaction = {
        amount,
        card: imprint.number,
        creditCardId: imprint.id,
        callNo: res.data.payboxCaptureSubscriber.NUMAPPEL,
        transactionNo: res.data.payboxCaptureSubscriber.NUMTRANS,
        ...filterByKeys(imprint, ['type', 'brand', 'country']),
      };

      return bookSchoolLesson(transaction);
    })
      .catch((err) => {
        setLoading(false);
        setErrorPbx(err.message);
        scrollToError();
      });
  }

  /* */
  const payWithNewCard = (values, { setSubmitting }) => {
    // console.log(values);
    const creditCard = {
      ...values,
      name: '3DS web EDG',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    setErrorPbx(null);

    return capturePbx(creditCard)
      .then((res) => {
        const transaction = {
          amount: captureData.amount,
          callNo: res.NUMAPPEL,
          transactionNo: res.NUMTRANS,
          card: anonymizeNumber(creditCard.number),
          ...filterByKeys(creditCard, ['type', 'brand', 'country']),
        };

        return bookSchoolLesson(transaction);
      })
      .catch((err) => {
        setLoading(false);
        setErrorPbx(err.message);
        scrollToError();
        if (setSubmitting) setSubmitting(false);
      });
  };

  if (!lessonSlotId || (event !== 'signing_complete' && event !== 'signing_done')) {
    return <PageNotFound />;
  }
  const isStagered = paymentType === 'staggered';

  const options = [
  ]
  const includedOptionsPrices = options.filter((val) => val.isIncluded).reduce((sum, acc) => {
    sum += acc.value;
    return sum;
  }, 0.0)
  const excludedOptionsPrices = options.filter((val) => !val.isIncluded).reduce((sum, acc) => {
    sum += acc.value;
    return sum;
  }, 0.0)

  const stageredPrice = 40;

  const lessonPrice = lessonSlot?.price - includedOptionsPrices;
  const subTotalPrice = lessonPrice + includedOptionsPrices;
  const totalPrice = isStagered ? subTotalPrice + stageredPrice : subTotalPrice;

  const otherPayment = ((lessonPrice + stageredPrice) / 3).toFixed(2);
  const firstPayment = (((lessonPrice + stageredPrice) - otherPayment - otherPayment) + includedOptionsPrices + excludedOptionsPrices).toFixed(2);



  // const lessonPrice = isStagered ? lessonSlot.price + stageredPrice - licensePrice : lessonSlot.price;

  return (
    <Layout>
      <Typography variant="h4" className={classes.header}>Paiement de l'inscription</Typography>
      <Box m={1}>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
      <Box m={1}>
        {warn && <Alert severity={'warning'}>{warn}</Alert>}
      </Box>
      <Box m={1}>
        {info && <Alert severity={infoLevel}>{info}</Alert>}
      </Box>
      {loading && <CircularProgress color="inherit" size={16} />}
      {success && !error && (!lessonSlot || (lessonSlot.places - lessonSlot.studentsCount !== 0)) &&
        <>
          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={5}>
            <Grid container item xs={12} sm={12} md={5}
              direction="column"
              alignItems="center">
              <Typography className={classes.title} variant="h5">Votre commande</Typography>
              {lessonSlot ? (
                <>
                  <TableContainer className={classes.table} component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="right" className={classes.tableCell}>
                            Élève
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {booking.firstname} {booking.lastname}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" className={classes.tableCell}>
                            Intitulé
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {lessonSlot.lesson}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" className={classes.tableCell}>
                            Professeur
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {lessonSlot.teacher}
                          </TableCell>
                        </TableRow>
                        {lessonSlot.recurrent ? (
                          <TableRow>
                            <TableCell align="right" className={classes.tableCell}>
                              Tous les
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {getDayOfWeek(lessonSlot.date)}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell align="right" className={classes.tableCell}>
                              Le
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {formatDate(lessonSlot.date, 'D/MM/YYYY')}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell align="right" className={classes.tableCell}>
                            Horaire
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {`De ${formatTime(lessonSlot.startHour)} à ${formatTime(lessonSlot.endHour)}`}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" className={classes.tableCell}>
                            Niveau
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {levels.find(l => l.value === lessonSlot.level).label}
                          </TableCell>
                        </TableRow>
                        {lessonPrice && (
                          <TableRow>
                            <TableCell align="right" className={classes.tableCell}>
                              Cours
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {`${lessonPrice}€`}
                            </TableCell>
                          </TableRow>
                        )}
                        {includedOptionsPrices !== 0 && (
                          options.filter((val) => val.isIncluded).map((option, i) => (
                            <TableRow key={`included-${i}`}>
                              <TableCell align="right" className={classes.tableCell}>
                                {option.name}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {`${option.value}€`}
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                        {excludedOptionsPrices !== 0 && (
                          <>
                            {includedOptionsPrices !== 0 && (
                              <TableRow>
                                <TableCell align="right" className={classes.tableCellBold}>
                                  Sous-total Cours
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {`${lessonPrice + includedOptionsPrices}€`}
                                </TableCell>
                              </TableRow>
                            )}
                            {options.filter((val) => !val.isIncluded).map((option, j) => (
                              <TableRow key={`included-${j}`}>
                                <TableCell align="right" className={classes.tableCell}>
                                  {option.name}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {`${option.value}€`}
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        )}
                        {(includedOptionsPrices !== 0 || excludedOptionsPrices !== 0) && (
                          <TableRow>
                            <TableCell align="right" className={classes.tableCellBold}>
                              Sous-Total
                            </TableCell>
                            <TableCell className={classes.tableCellBold}>
                              {`${subTotalPrice + excludedOptionsPrices}€`}
                            </TableCell>
                          </TableRow>
                        )}
                        {false && (
                          <TableRow>
                            <TableCell align="right" className={classes.tableCell}>
                              Type de règlement
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <RadioGroup row aria-label="payment type" name="payType" value={paymentType} onChange={(e) => setPaymentType(e.target.value)} className={classes.group}>
                                <FormControlLabel value={'cash'} control={<Radio size='small' />} label="Comptant" className={classes.labelRow} />
                                <FormControlLabel value={'staggered'} control={<Radio size='small' />} label="Échelonné" className={classes.labelRow} />
                              </RadioGroup>
                            </TableCell>
                          </TableRow>
                        )}
                        {isStagered ? (
                          <>
                            <TableRow>
                              <TableCell align="right" className={classes.tableCell}>
                                Echéancier
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {`${startingDate.format('DD/MM/YYYY')} - ${firstPayment}`}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="right" className={classes.tableCell} />
                              <TableCell className={classes.tableCell}>
                                {`${nextPaymentDate.format('DD/MM/YYYY')} - ${otherPayment}`}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="right" className={classes.tableCell} />
                              <TableCell className={classes.tableCell}>
                                {`${finalPaymentDate.format('DD/MM/YYYY')} - ${otherPayment}`}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="right" className={classes.tableCell}>
                                Frais de gestion
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                40€
                              </TableCell>
                            </TableRow>
                          </>
                        ) : null}
                        <TableRow>
                          <TableCell align="right" className={classes.tableCellBold}>
                            TOTAL
                          </TableCell>
                          <TableCell className={classes.tableCellBold}>
                            {`${totalPrice + excludedOptionsPrices}€`}
                          </TableCell>
                        </TableRow>
                        {lessonSlot.displayPrice && (
                          <TableRow>
                            <TableCell align="right" className={classes.tableCell}>
                              Reste à payer
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {`${(lessonSlot.displayPrice - lessonSlot.price).toFixed(2)}€`}
                              &nbsp;(à régler au professeur)
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>) : (null)}
            </Grid>
            <Modal
              open={modalIsOpen}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <Grid id="error-anchor" container item xs={12} sm={12} md={7}
                style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', position: 'relative' }}
                direction="column"
                alignItems="center">
                <Typography variant="h6" className={classes.title}>{'Conditions générales d’inscription individuelle'}</Typography>
                <Alert className={`${classes.alert} ${errorPbx ? '' : classes.hidden}`} severity="error">{errorPbx ? errorPbx : null}</Alert>
                <iframe title={'CGV'} src={CGV} width="90%" height="300px" />
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={{ paddingTop: '20px' }}>{`Veuillez accepter les Conditions générales d’inscription individuelle`}</FormLabel>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="end"
                      control={<Checkbox color="primary" onChange={(e) => setDocumentValidation(e.target.checked)} />}
                      label={`En cochant cette case, j’accepte les Conditions générales d’inscription individuelle.`}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
                <Button variant="contained" color="secondary" disabled={!documentValidation} onClick={() => setModalIsOpen(false)}>
                  {'Continuer'}
                </Button>
              </Grid>
            </Modal>
            <Grid id="error-anchor" container item xs={12} sm={12} md={7}
              direction="column"
              alignItems="center">
              <Typography variant="h5" className={classes.title}>Votre moyen de paiement</Typography>
              <Alert className={`${classes.alert} ${errorPbx ? '' : classes.hidden}`} severity="error">{errorPbx ? errorPbx : null}</Alert>
              {lessonSlot ? (
                <>
                  <Payment3DS
                    amount={isStagered ? firstPayment * 100 : lessonSlot.price * 100}
                    imprint={(accountCreditCards && accountCreditCards[0]) || null}
                    session3DS={session3DS}
                    payWithNewCard={payWithNewCard}
                    payWithCardImprint={payWithCardImprint} />
                </>
              ) : (null)}
            </Grid>
          </Grid>
        </>}
    </Layout>
  );

};

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(3),
  },
  group: {
    justifyContent: 'left',
    height: '20px',
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  subTitle: {
    margin: 16,
    height: '20px',
  },
  table: {
    marginTop: theme.spacing(0),
  },
  tableCellBold: {
    height: '20px',
    padding: 16,
    fontWeight: 800
  },
  tableCell: {
    height: '20px',
    padding: 16,
  },
  labelRow: {
    height: '20px',
  },
  hidden: {
    display: 'none !important',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));
