import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';


export const SuccessfullBookingDialog = ({ open, onClose }) => {

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth={true} >
      <DialogTitle id="form-dialog-title">Succès de l'inscription</DialogTitle>
      <DialogContent>
        <Typography>
          Votre inscription a bien été enregistrée.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Bien compris
        </Button>
      </DialogActions>
    </Dialog>
  );
};