import { createMuiTheme } from '@material-ui/core/styles';

import { palette } from './palette';
import { typography } from './typography';
import { breakpoints } from './breakpoints';
import { mergeDeep } from '../utils/mergeDeep';


const theme = createMuiTheme({
  palette,
  typography,
  breakpoints,
  shape: { borderRadius: 10 },
  duration: 200
});

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('dashboard')]: {
    fontSize: '2.4rem',
  },
};

export const golfTheme = golf => {
  if (!golf) return theme;

  const option = golf.options.find(o => o.name === '_themeMUI');
  return option
    ? mergeDeep(theme, JSON.parse(option.value))
    : theme;
};
