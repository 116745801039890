import React, { useContext } from 'react';
import moment from 'moment';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CalendarToday, Face, Group, School } from '@material-ui/icons';
import { LessonCardItem } from './LessonCardItem';
import { PlanningContext } from '../../context/PlanningContext';
// import { translateDay } from '../../utils/date';


export const LessonCard = ({ lesson, disabled = false }) => {

  const { getLessonSlot } = useContext(PlanningContext);
  const classes = useStyles();

  if (!lesson.nextSlot) return null;

  const items = [
    <LessonCardItem icon={<Face fontSize="large" />} text={`${getAge(lesson.age)}`} key="age" />,
    <LessonCardItem icon={<School fontSize="large" />} text={`${getLevel(lesson.level)}`} key="level" />,
    <LessonCardItem icon={<CalendarToday fontSize="large" />} text={`${moment(lesson.slot.date, 'x').format('dddd')} ${moment(lesson.slot.startHour, 'HH:mm').format('HH:mm')}`} key="date" />,
    <LessonCardItem icon={<Group fontSize="large" />} text={`${lesson.studentsCount}/${lesson.places}`} key="slot" />,
  ];

  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <Card className={classes.card} elevation={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column">
              <Grid item xs>
                <Typography gutterBottom variant="h6">
                  {lesson.title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1"><b>{(lesson.displayPrice || lesson.price).toFixed(2)} €</b></Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Typography variant="body2">
              Animé par <b>{lesson.teacher}</b>
            </Typography>
            <Grid container justify="space-around" alignItems="flex-start" className={classes.container}>
              {items.map((item) => (item))}
            </Grid>
            <div className={classes.descriptionContainer}>
              <Typography variant="caption" className={classes.description}>
                {lesson.description}
              </Typography>
            </div>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <Button disabled={disabled} size="medium" className={classes.button} color="primary" variant="contained" onClick={() => getLessonSlot({ id: lesson.nextSlot })}>
              S'inscrire
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

function getAge(age) {
  const ages = {
    'baby': 'Poussin (4-6 ans)',
    'kid1': 'Jeune (6-9 ans)',
    'kid2': 'Jeune (10-13 ans)',
    'kid3': 'Jeune (14-17 ans)',
    // 'kid4': 'Enfant (13-17 ans)',
    // 'teenager': '13-18 ans',
    'teenager1': 'Espoir (-13 ans)',
    'teenager2': 'Espoir (+13 ans)',
    // 'adult': 'Adultes (18 ans et +)',
    'adult': '+18 ans',
  }
  return ages[age] || 'Tout public';
}

function getLevel(level) {
  const levels = {
    'all': 'Tout niveau',
    'beginner': 'Débutant',
    'advanced': 'Espoir',
    'expert': 'Confirmé',
  }
  return levels[level] || 'Tout niveau';
}

const useStyles = makeStyles((theme) => ({
  description: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
  descriptionContainer: {
    height: theme.spacing(5),
    padding: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(2),
    height: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));
