import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Field } from './Field';
import clsx from 'clsx';
import { ButtonBar } from './ButtonBar';


export const Form = ({
  children,
  onSubmit,
  onChange,
  error,
  loading = false,
  btnLabel = 'Valider',
  onClose,
  centered = false,
  closeBtnLabel = 'Annuler',
  className,
  fields = [] }) => {

  const classes = useStyles();

  return (
    <form onSubmit={onSubmit} className={clsx(classes.formContainer, className)}>

      {fields.map(field => (
        <Field key={field.name} onChange={onChange} fieldType={field.type} {...field} />
      ))}

      {error && <div className={classes.error} data-testid="error">{error}</div>}

      {children}

      {!!onSubmit &&
        <ButtonBar
          justify={centered ? 'center' : undefined}
          buttons={[{
            label: closeBtnLabel,
            onClick: onClose,
            hide: !onClose
          }, {
            variant: 'contained',
            label: loading ? <CircularProgress color='inherit' /> : btnLabel,
            type: 'submit',
          }]} />
      }
    </form>
  );
};

const useStyles = makeStyles(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: '400px',
    // width: '100%',
    width: '400px'
  },
  error: {
    color: 'red',
    marginTop: '10px',
  },
}));
