import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { WidgetTitle } from './WidgetTitle';
import { makeStyles } from '@material-ui/core/styles';


export const Widget = ({ title, Comp, size = 's', ...props }) => {

  const classes = useStyles(props);

  const sizes = {
    s: { xs: 12, sm: 12, md: 4, lg: 3 },
    m: { xs: 12, sm: 12, md: 6, lg: 6 },
    l: {},
  };

  return (
    <Grid item {...sizes[size]} style={{ display: 'flex', justifyContent: 'left' }}>
      <Paper className={classes.container} elevation={0}>
        <Grid container direction="column" justify="space-between">
          {title && <WidgetTitle>{title}</WidgetTitle>}
          <Comp />
        </Grid>
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    background: bg,
    color: ({ white }) => white ? theme.palette.common.white : theme.palette.common.black,
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
}));

const bg = ({ background }) => {
  const base = 'white';
  if (!background) return base;

  const c1 = background[0] || base;
  return `linear-gradient(${c1}, ${background[1] || c1})`
}

