import React, { useState } from 'react';
import { Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Layout } from './common/Layout';
import { Lessons } from './planning/Lessons';
import { Calendar } from './planning/Calendar';
import { LessonDialog } from './planning/LessonDialog';
import { LessonSlotDialog } from './planning/LessonSlotDialog';


export const AdminLessons = () => {

  const classes = useStyles();

  // const [lessons, setLessons] = useState([])
  const [recurrentLessons, setRecurrentLessons] = useState([])

  const updateLessons = (lessons, recurrent) => {
    if (recurrent) {
      setRecurrentLessons(lessons);
    } else {
      // setLessons(lessons);
    }
  }

  return (
    <Layout>
      <Typography variant="h4">Planning</Typography>

      <Grid container direction="row" justify="flex-start" alignItems="stretch">

        <Grid item sx={2}
        // style={{ height: '100%' }}
        >
          {/* <Grid item xs>
            <Paper className={classes.paper}>
              <Lessons lessons={lessons} setLessons={setLessons} />
            </Paper>
          </Grid> */}

          {/* <Grid item xs> */}
          <Paper className={classes.paper} style={{ maxHeight: 'calc(100vh - 200px)', height: 'calc(100vh - 200px)', overflow: 'hidden' }}>
            <Lessons recurrent lessons={recurrentLessons} setLessons={setRecurrentLessons} />
          </Paper>
          {/* </Grid> */}
        </Grid>

        <Grid item xs
        // style={{ height: '100%' }}
        >
          <Paper className={classes.paper} style={{ maxHeight: 'calc(100vh - 200px)', height: 'calc(100vh - 200px)', overflow: 'hidden' }}>
            <Calendar editing />
            <LessonSlotDialog />
          </Paper>
        </Grid>

      </Grid>
      <LessonDialog updateLessons={updateLessons} />
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    margin: theme.spacing(2),
    boxShadow: theme.shadows[1],
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(3),
  },
  errorButton: {
    color: theme.palette.error.main
  },
  input: {
    display: 'none',
  },
  textfield: {
    margin: theme.spacing(0.75),
    width: theme.spacing(55),
  }
}));
