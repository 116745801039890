import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline, Toolbar } from '@material-ui/core';

import { AppContext } from '../../context/AppContext';
import { drawerWidth } from '../../utils/layout';
import { AppHeader } from './AppHeader';
import { AppSidebar } from './AppSidebar';


export const Layout = ({ children }) => {

  const { user } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const openDrawer = () => {
    setOpen(true);
    window.dispatchEvent(new Event('resize'));
  }

  const closeDrawer = () => {
    setOpen(false);
    window.dispatchEvent(new Event('resize'));
  }

  if (!user) {
    return null;
  }

  return (
    <div className={classes.root} data-testid="page">
      <CssBaseline />
      <AppHeader open={open} openDrawer={openDrawer} />
      <AppSidebar open={open} drawerWidth={drawerWidth} closeDrawer={closeDrawer} />
      <main className={
        clsx(classes.content, {
          [classes.contentShift]: open,
        })
      }>
        <Toolbar className={'not-printed'} />
        {children}
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  content: {
    height: '100%',
    width: '100%',
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.duration,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.duration,
    }),
    marginLeft: 0,
  },
}));
