import config from './firebase-config.json';
import firebase from 'firebase/app';
import 'firebase/auth';


firebase.initializeApp(config);

export const autoRefreshToken = (fbUser, user, setUser) => {

  stopAutoRefreshToken();
  firebase.autoRefresh = setInterval(() => {
    fbUser.getIdToken(true)
      .then(token => {
        setUser({ ...user, token });
      });
  }, 40 * 60 * 1000);
};

export const stopAutoRefreshToken = () => {

  if (!firebase.autoRefresh) return;
  clearInterval(firebase.autoRefresh);
  firebase.autoRefresh = null;
};

export default firebase;
