import React, { useContext } from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';

import { AppContext } from './context/AppContext';
import { golfTheme } from './theme/theme';
import { AppRoute } from './components/common/AppRoute';
import { PageNotFound } from './components/PageNotFound';
import {
  publicRoutes,
  restrictedRoutes,
  hiddenRestrictedRoutes,
  adminRoutes,
} from './routes';
import SubmitForm3DS from './components/3ds/SubmitForm';
import Response3DS from './components/3ds/Response';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';


export const App = () => {

  const { golf } = useContext(AppContext);

  return (
    <ThemeProvider theme={golfTheme(golf)}>
      <CssBaseline />
      <Switch>
        <Route exact path="/3ds" component={SubmitForm3DS} />
        <Route exact path="/3ds/response" component={Response3DS} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-conditions" component={TermsAndConditions} />
        <Route
          path="/:slug"
          render={({ match: { url } }) => {
            if (!golf) return <PageNotFound />;

            return (
              <Switch>
                {publicRoutes.map(({ path, page, ...route }) => (
                  <AppRoute key={path} path={`${url}/${path}`} Page={page} {...route} />
                ))}
                {restrictedRoutes.map(({ exact, page, path }) => (
                  <AppRoute path={`${url}/${path}`} key={`${url}/${path}`} Page={page} exact={exact} restricted />
                ))}
                {hiddenRestrictedRoutes.map(({ path, page, ...route }) => (
                  <AppRoute key={path} path={`${url}/${path}`} Page={page} {...route} restricted />
                ))}
                {adminRoutes.map(({ exact, page, path }) => (
                  <AppRoute path={`${url}/${path}`} key={`${url}/${path}`} Page={page} exact={exact} restricted admin />
                ))}
                <AppRoute path="*" Page={PageNotFound} />
              </Switch>
            );
          }}
        />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </ThemeProvider>
  );
};
