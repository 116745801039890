import React, { useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';

import { AppContext } from '../../context/AppContext';


export const AppRoute = ({ Page, restricted, ...props }) => {
  const Comp = restricted ? PrivateRoute : Route;
  const { user, golf } = useContext(AppContext);
  const history = useHistory();

  if (user && user.role !== 'customer' && user.golfId !== golf.golfId) {
    history.push(`/${user.golfSlug}/`);
    window.location.reload();
    return null;
  }

  return (
    <Comp {...props}>
      <Page />
    </Comp>
  );
};

//

const PrivateRoute = ({ children, admin = false, ...rest }) => {
  const { user, golf, isAdmin } = useContext(AppContext);

  return (
    <Route {...rest} render={
      ({ location }) => (!admin && user) || (admin && isAdmin())
        ? children
        : <Redirect to={{
          pathname: '/' + golf.slug + '/',
          state: { from: location }
        }} />
    } />
  );
};
