import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { Layout } from './common/Layout';
import { TeacherItem } from './teachers/TeacherItem';
import { AppContext } from '../context/AppContext';
import { PlanningContext } from '../context/PlanningContext';


export const Teachers = () => {

  const { golf, teachers } = useContext(AppContext);
  const { filters, updateFilters } = useContext(PlanningContext);

  const urlPlanning = `/${golf.slug}/ecole`;

  let t = [];

  if (teachers) {
    t = teachers.map(teacher => ({
      ...teacher,
      name: `${teacher?.firstname} ${teacher?.lastname}`,
      age: '70',
      description: '',
      handicap: '15,5',
      job: 'Entraineur de golf',
      experience: '6 ans',
      image: 'https://images.unsplash.com/flagged/photo-1558759103-d4d02c7ee13f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=634&q=80'
    }));
  }

  const handleClick = (id) => {
    // Remove the precendent filters
    const { teachers, age, level, recurrent, ...f } = filters;
    updateFilters({
      ...f,
      age: -1,
      level: -1,
      day: -1,
      recurrent: 1,
      teachers: [`${id}`]
    });
  };

  return (
    <Layout>
      <Typography variant="h4">Nos professeurs de golf</Typography>
      <div>
        <Grid container direction="row" justify="flex-start" alignItems="center" >
          {t.map((item) => <TeacherItem teacher={item} onClick={handleClick} url={urlPlanning} key={item.id} />)}
        </Grid>
      </div>
    </Layout>
  );
}
