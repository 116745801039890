import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import moment from 'moment';

import { getWeatherIcon } from '../../utils/weather';
import { formatDate } from '../../utils/date';
import { WeatherPrevisionsWidget } from '../common/WeatherPrevisionsWidget';


export const WeatherNextDays = ({ weather }) => (
  <Grid container direction="row" justify="space-around" alignItems="center">
    <Grid item>
      <Typography variant="caption">
        <Box fontWeight="fontWeightMedium">
          Prévisions
        </Box>
      </Typography>
    </Grid>
    {weather.slice(1, 4)
      .map((day) =>
        <Grid item key={day.dt}>
          <WeatherPrevisionsWidget icon={getWeatherIcon(day.weather[0].icon)} day={formatDate(moment.unix(day.dt), 'DD/MM')} temp={~~day.temp.day} />
        </Grid>)
    }
  </Grid>
);
