import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

import { AppContext } from '../../context/AppContext';
import { WeatherMain } from './WeatherMain';
import { WeatherSecond } from './WeatherSecond';
import { WeatherNextDays } from './WeatherNextDays';
import { getWeatherURL } from '../../utils/weather';


export const WeatherWidget = () => {

  const classes = useStyles();
  const { golf } = useContext(AppContext);

  const [weather, setWeather] = useState(null);

  const url = getWeatherURL(golf, process.env.REACT_APP_OPENWEATHERMAP_API_KEY);

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => setWeather(data));
  }, []);

  if (!weather) return null;

  return (
    <>
      <WeatherSecond weather={weather.current} />
      <WeatherMain weather={weather.current} />

      <Divider className={classes.divider} />
      <WeatherNextDays weather={weather.daily} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  }
}));
