import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { ArrowForwardIos, Event, Group, Schedule, Timer } from '@material-ui/icons';

import image from '../../assets/swing.jpg';
import { LessonSlotTeacherPrice } from './LessonSlotTeacherPrice';
import { ButtonBar } from '../common/ButtonBar';
import { AppContext } from '../../context/AppContext';
import { formatTime, formatDate, getDuration } from '../../utils/date';
import { LessonSlotDataItem } from './LessonSlotDataItem';
import { queryLessonSlotStudents } from '../../utils/gqlQueries';
import { PlanningContext } from '../../context/PlanningContext';


export const LessonSlotData = ({ lessonSlot, onClose, setIsBooking }) => {
  const { lessonSlot: rereshedLessonSlot } = useContext(PlanningContext);

  const classes = useStyles();
  const {
    isAdmin,
    fetchApi,
  } = useContext(AppContext);
  const [students, setStudents] = useState([]);

  const dateTitle = rereshedLessonSlot.recurrent
    ? 'Réccurent'
    : formatDate(rereshedLessonSlot.date, 'dddd')

  const items = [
    <LessonSlotDataItem icon={<Event fontSize="large" />} text={`${formatDate(rereshedLessonSlot.date, 'dddd')}`} title={dateTitle} key="date" />,
    <LessonSlotDataItem icon={<Schedule fontSize="large" />} text={`${formatTime(rereshedLessonSlot.startHour)}`} title="Début" key="hour" />,
    <LessonSlotDataItem icon={<Timer fontSize="large" />} text={`${getDuration(rereshedLessonSlot.startHour, rereshedLessonSlot.endHour)}`} title="Durée" key="duration" />,
    <LessonSlotDataItem icon={<Group fontSize="large" />} text={`${rereshedLessonSlot.studentsCount}/${rereshedLessonSlot.places}`} title="places" key="slot" />,
  ];

  useEffect(() => {
    if (isAdmin()) {
      fetchApi(queryLessonSlotStudents, ({
        ids: [rereshedLessonSlot.id],
      })).then((response) => {
        if (!response || !response.data) return;
        const res = response.data.getSchoolLessonSlotStudents;
        setStudents(res);
      });
    }
  }, []);


  return (
    <>
      <img alt="background" className={classes.image} src={image} />
      <Grid container className={classes.container}>
        {items.map((item) => (item))}
      </Grid>
      <LessonSlotTeacherPrice />
      {!isAdmin() ? (
        <>
          {(rereshedLessonSlot.recurrent || new Date(rereshedLessonSlot.date) >= new Date()) ? (
            <ButtonBar
              buttons={[{
                label: 'Retour',
                onClick: onClose,
              }, {
                label: 'Réserver maintenant',
                disabled: rereshedLessonSlot.studentsCount >= rereshedLessonSlot.places,
                variant: 'contained',
                endIcon: <ArrowForwardIos fontSize="small" />,
                onClick: () => {
                  setIsBooking(true);
                },
              }]} />
          ) : (
            <Typography variant="body1" className={classes.error}>Ce cours est déjà passé. Il est impossible de le réserver.</Typography>
          )}
        </>
      ) : (
        <div className={classes.listContainer}>
          <List dense={true} className={classes.list}>
            {students && students.map((student, index) => index % 2 === 0 ? (
              <ListItem>
                <ListItemText secondary={`${index + 1}.`} />
                <ListItemText
                  primary={student.lastname}
                  secondary={student.firstname}
                />
              </ListItem>
            ) : (null))}
          </List>
          <List dense={true} className={classes.list}>
            {students && students.map((student, index) => index % 2 !== 0 ? (
              <ListItem>
                <ListItemText secondary={`${index + 1}.`} />
                <ListItemText
                  primary={student.lastname}
                  secondary={student.firstname}
                />
              </ListItem>
            ) : (null))}
          </List>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(0),
    background: theme.palette.card.background,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10
  },
  image: {
    width: '100%', height: '100%', borderTopLeftRadius: 10, borderTopRightRadius: 10
  },
  listContainer: {
    display: 'flex',
  },
  list: {
    marginInline: '7.5px'
  },
  error: {
    color: theme.palette.error.main,
    paddingBottom: '15px'
  }
}));
