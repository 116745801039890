import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

/* */
const styles = theme => ({
  dialogContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: `${theme.spacing(3)}px`,
  },
  dialogTitle: {
    textAlign: 'center',
  },
  iframe: {
    flex: 1,
    width: '100%',
    height: '100%',
    display: 'block',
    overflow: 'hidden',
  },
});

/* */
class DialogPayment3DS extends React.Component {

  render() {
    const {
      open,
      onClose,
      classes,
      sessionId,
    } = this.props;

    return (
      <Dialog
        open={open}
        fullScreen={true}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}>
        <DialogTitle className={classes.dialogTitle}>
          {'Demande d\'authentification auprès de votre banque...'}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <iframe
            height="100%"
            width="100%"
            frameBorder="0"
            title="request3ds"
            className={classes.iframe}
            src={`${window.location.origin}/3ds?sessionId=${sessionId}`} />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={onClose}
            variant="outlined">
            {'Annuler'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogPayment3DS);
