export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    dashboard: 960,
    lg: 1600,
    xl: 1920,
  },
}
