import React, { useContext } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { AppContext } from '../../context/AppContext';


export const DisconnectDialog = ({ open, onClose }) => {

  const { signout } = useContext(AppContext);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth={true} >
      <DialogTitle id="form-dialog-title">Déconnexion</DialogTitle>
      <DialogContent>
        <Typography>
          Veuillez confirmer la déconnexion de votre compte.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
        <Button onClick={signout} variant="contained" color="primary">
          Se déconnecter
        </Button>
      </DialogActions>
    </Dialog>
  );
};
