import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, CircularProgress } from '@material-ui/core';
import {
  Grid,
  Paper,
  Radio,
  Button,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
/* */
import CompleteCreditCardForm from './CompleteCreditCardForm';
import { createValidationSchema, validators } from '../../utils/validation';
import Alert from '../common/Alert';
import DialogPayment3DS from './DialogPayment3DS';
import CreditCardPreview from './CreditCardPreview';

/* */
const validationSchema = createValidationSchema({
  cvv: validators.cvv,
  dueDate: validators.dateFourDigits,
});

/* */
const styles = theme => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  panel: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  radio: {
    padding: 0,
    marginRight: theme.spacing(2),
  },
  marginT: {
    marginTop: theme.spacing(2),
  },
});

/* */
class Payment extends React.Component {
  /* */
  static defaultProps = {
    amount: 5000,
    numberOfInstallments: 1,
    session3DS: null, // required
  };

  /* */
  constructor(props) {
    super(props);

    this.state = {
      expanded: null,
      pannel: null,
      isLoading: false,
      // data 3DS
      error: null,
      sessionId: null,
      showIframe: false,
    };

    this.id3D = null;
    this.form = React.createRef();
    this.imprintRequired = props.numberOfInstallments > 1;
  }

  /* */
  componentDidMount() {
    const { imprint } = this.props;

    this.setState({ expanded: imprint && 'imprint', panel: imprint && 'imprint' });

    // add listener 3ds posted response
    window.addEventListener('message', this.handleMesssage3DS);
  }

  /* */
  componentDidUpdate(prevProps) {
    const { imprint } = this.props;

    if (prevProps.imprint !== imprint) this.setState({ expanded: imprint && 'imprint', panel: imprint && 'imprint' });
  }

  /* */
  componentWillUnmount() {
    // clean listener
    window.removeEventListener('message', this.handleMesssage3DS);
  }

  /* */
  handleChange = panel => (event, expanded) => {
    this.setState({ expanded: expanded ? panel : false, panel });
  };

  // Catch iframe 3DS message
  handleMesssage3DS = (event) => {
    if (!event.data.type || event.data.type !== 'response3DS') return;

    // console.log('Message', event.data);
    const { status, id3D } = event.data;

    if (status === 'success') {
      this.id3D = id3D;
      this.setState({ showIframe: false });
      this.form.current.props.submitForm();
    } else {
      this.handleError3DS();
    }
  }

  /* */
  handleError3DS() {
    this.id3D = null;
    this.form.current.props.setSubmitting(false);
    this.setState({
      showIframe: false,
      error: 'Erreur d\'authentification 3D Secure.',
    });
  }

  /* */
  onPayClick() {
    const {
      payWithCardImprint,
    } = this.props;

    this.setState({ isLoading: true });
    return payWithCardImprint();
  }

  /* */
  renderCardImprint() {
    const {
      amount,
      classes,
      imprint,
      numberOfInstallments,
    } = this.props;
    const { isLoading } = this.state;

    return (
      <React.Fragment>
        <Box display="flex" justifyContent="center">
          <CreditCardPreview
            brand={imprint.brand}
            number={imprint.number}
            dueDate={imprint.dueDate} />
        </Box>
        {imprint.isExpired && (
          <React.Fragment>
            <Alert
              severity="error"
              className={classes.marginT}>
              {'La carte est expirée.'}
            </Alert>
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="outlined"
              className={classes.marginT}
              onClick={() => this.props.history.replace('/account/creditcard')}>
              {'Changer'}
            </Button>
          </React.Fragment>
        )}
        {imprint.isSoonExpired && (
          <Alert
            severity="warning"
            className={classes.marginT}>
            {'La carte arrive bientôt à expiration.'}
          </Alert>
        )}
        {!imprint.isExpired && (
          <React.Fragment>
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
              disabled={isLoading}
              className={classes.marginT}
              onClick={() => this.onPayClick()}>
              {isLoading && (
                <CircularProgress
                  size={20}
                  color='secondary'
                  style={{ marginRight: '10px' }}
                />
              )}
              {`Payer ${(amount / 100).toFixed(2)}€`}
            </Button>
            {numberOfInstallments > 1 && (
              <Typography
                component="p"
                align="center"
                variant="caption"
                className={classes.marginT}>
                {`Paiement en ${numberOfInstallments} mensualités`}
              </Typography>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  /* */
  renderCardImprintPanel() {
    const { classes } = this.props;
    const { expanded, panel } = this.state;
    const { imprintRequired } = this;

    if (imprintRequired) {
      return (
        <Grid item xs={12} sm={9} md={6}>
          <Paper className={classes.paper}>
            <Typography
              variant="subtitle1"
              component='h2'>
              {'Ma carte enregistrée'}
            </Typography>
            <br />
            {this.renderCardImprint()}
          </Paper>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} sm={9} md={6}>
        <Accordion
          expanded={expanded === 'imprint'}
          onChange={this.handleChange('imprint')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}>
            <Radio
              className={classes.radio}
              checked={panel === 'imprint'} />
            <Typography
              variant="subtitle1"
              component='h2'>
              {'Ma carte enregistrée'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.panel}>
            {this.renderCardImprint()}
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  }

  /* */
  getCardInfos = () => this.form.current.getExtraInfos();

  /* */
  onFormSubmit = (values, actions) => {
    const {
      amount,
      session3DS,
      payWithNewCard,
    } = this.props;
    const { id3D } = this;

    const newValues = {
      ...values,
      ...this.getCardInfos(),
      id3D,
    };

    if (id3D !== null) {
      payWithNewCard(newValues, actions);
      return;
    }

    // build unique 3DS sessionId
    const sessionId = amount
      + Number(moment().format('x'))
      + Math.floor(Math.random() * (10 ** 12));

    // store temporarily data
    sessionStorage.setItem(`cart_${sessionId}`, JSON.stringify({
      ...session3DS,
      amount,
      id: sessionId,
      creditCard: {
        cvv: values.cvv,
        number: values.number.replace(/\s/g, ''),
        dueDate: values.dueDate.replace(/\D/g, ''),
      },
    }));

    // load iframe 3DS
    this.setState({
      sessionId,
      error: null,
      showIframe: true,
    });
  }

  /* */
  renderNewCardForm() {
    const {
      amount,
      imprint,
      numberOfInstallments,
    } = this.props;

    const { error } = this.state;
    const { imprintRequired } = this;

    const imprintUse = imprintRequired ? 'required' : 'enabled';

    const submitText = `Payer ${(amount / 100).toFixed(2)}€ `;
    const submitHintText = numberOfInstallments > 1 ? `Paiement en ${numberOfInstallments} mensualités` : null;

    return (
      <React.Fragment>
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
        <Formik
          initialValues={{
            cvv: '',
            number: '',
            dueDate: '',
          }}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={this.onFormSubmit}
          validationSchema={validationSchema}
        >
          {(props) =>
            <CompleteCreditCardForm
              {...props}
              innerRef={this.form}
              submitButtonText={submitText}
              submitButtonHintText={submitHintText}
              imprintUse={imprint ? 'disabled' : imprintUse} />
          }
        </Formik>
      </React.Fragment>
    );
  }

  /* */
  renderNewCardPanel() {
    const {
      classes,
      imprint,
    } = this.props;
    const { expanded, panel } = this.state;

    if (!imprint) {
      return (
        <Grid item xs={12} sm={9} md={6}>
          <Paper className={classes.paper}>
            {this.renderNewCardForm()}
          </Paper>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} sm={9} md={6}>
        <Accordion
          expanded={expanded === 'other'}
          onChange={this.handleChange('other')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}>
            <Radio
              className={classes.radio}
              checked={panel === 'other'} />
            <Typography
              variant="subtitle1"
              component='h2'>
              {'Autre carte bancaire'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.panel}>
            {this.renderNewCardForm()}
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  }

  /* */
  renderPanels() {
    const { imprint } = this.props;
    const { imprintRequired } = this;

    return (
      <React.Fragment>
        {imprint && this.renderCardImprintPanel()}
        {(!imprint || !imprintRequired) && this.renderNewCardPanel()}
      </React.Fragment>
    );
  }

  /* */
  renderDialog3DS() {
    const { showIframe, sessionId } = this.state;

    if (!sessionId) return null;

    return (
      <DialogPayment3DS
        open={showIframe}
        sessionId={sessionId}
        onClose={() => this.handleError3DS()} />
    );
  }

  /* */
  render() {
    return (
      <React.Fragment>
        <Grid
          container
          spacing={5}
          justify="center">
          {this.renderPanels()}
        </Grid>
        {this.renderDialog3DS()}
      </React.Fragment>
    );
  }
}

const PaymentWithRouter = withRouter(Payment);

export default withStyles(styles)(PaymentWithRouter);
