import MuiAlert from '@material-ui/lab/Alert';
import { useEffect, useState } from 'react';


const Alert = (props) => {
  const { autohide, position } = props;
  const [display, setDisplay] = useState('flex');

  useEffect(() => {
    if (autohide) {
      const timer = setTimeout(() => {
        setDisplay('none');
      }, 5000);

      return () => {
        clearTimeout(timer);
      }
    }
  }, []);

  return <MuiAlert
    elevation={6}
    variant="filled"
    style={{
      display,
      position,
      left: 25,
      bottom: 25,
    }}
    {...props} />;
}

export default Alert;