import React from 'react';
import {
  Button,
  // Switch,
  TextField,
  // FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* */
const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
});

/* */
class RegisterForm extends React.Component {
  /* */
  state = {
    checkedA: true,
  };

  /* */
  handleChange = name => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  /* */
  render() {
    const {
      errors,
      values,
      classes,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <div className="form">
        <TextField
          required
          fullWidth
          name='email'
          type='email'
          label={'E-mail'}
          variant='outlined'
          onBlur={handleBlur}
          value={values.email}
          onChange={handleChange}
          error={!!(errors.email)}
          helperText={errors.email}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          required
          fullWidth
          name='password'
          type='password'
          variant='outlined'
          label={'Mot de passe'}
          onBlur={handleBlur}
          value={values.password}
          onChange={handleChange}
          error={!!(errors.password)}
          helperText={errors.password}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          required
          fullWidth
          name='lastname'
          type='text'
          label='Nom'
          variant='outlined'
          onBlur={handleBlur}
          value={values.lastname}
          onChange={handleChange}
          error={!!(errors.lastname)}
          helperText={errors.lastname}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          required
          fullWidth
          name='firstname'
          type='text'
          label='Prénom'
          variant='outlined'
          onBlur={handleBlur}
          value={values.firstname}
          onChange={handleChange}
          error={!!(errors.firstname)}
          helperText={errors.firstname}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          required
          fullWidth
          name='phone'
          type='phone'
          label='Téléphone'
          variant='outlined'
          onBlur={handleBlur}
          value={values.phone}
          onChange={handleChange}
          error={!!(errors.phone)}
          helperText={errors.phone}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          className={classes.submit}>
          {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'S\'inscrire'}
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(RegisterForm);
