import React, { useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useTheme } from '@material-ui/core/styles';

import { PlanningContext } from '../../context/PlanningContext';
import { mutateLessonSlot } from '../../utils/gqlQueries';
import { AppContext } from '../../context/AppContext';
import moment from 'moment';
import './../../theme/theme.css'


export const Calendar = ({ editing }) => {

  const { fetchApi, isAdmin } = useContext(AppContext);
  const { getLessonSlot, lessonSlots, updateFilters, getLessonSlots } = useContext(PlanningContext);
  const { palette } = useTheme();

  const events = lessonSlots.map(ls => {

    const e = {
      ...ls,
      title: ls.lesson,
      start: `${ls.date} ${ls.startHour}`,
      end: `${ls.date} ${ls.endHour}`,
      backgroundColor: palette.eventColor[ls.valid ? ls.recurrent ? 'reccurent' : 'occasionnel' : 'needValid'],
    };
    if (ls.recurrent) {
      e.daysOfWeek = [new Date(ls.date).getDay()];
      e.startRecur = ls.date;
      e.startTime = ls.startHour;
      e.endTime = ls.endHour;
    }
    return e;
  });

  const modifEvent = ({ event, revert }) => {

    if (!event.end) {
      const end = new Date(event.start);
      end.setTime(event.start.getTime() + (60 * 60 * 1000));
      event.setEnd(end);
    }

    const { lessonId } = event.extendedProps;
    const _lessonSlot = {
      lessonId,
      date: event.startStr.split('T')[0],
      startHour: event.start.toLocaleTimeString('fr-FR'),
      endHour: event.end.toLocaleTimeString('fr-FR'),
    }

    if (event.id) {
      _lessonSlot.id = event.id;
      delete _lessonSlot.lessonId;
    }

    fetchApi(mutateLessonSlot(!!_lessonSlot.id), _lessonSlot).then(({ data }) => {
      const { createLessonSlot } = data;
      const reload = getLessonSlots();
      if (!createLessonSlot) return;
      event.setProp('id', createLessonSlot.id);
      reload.then(revert);
    }).catch(e => {
      console.error(e);
      revert();
    });
  }

  return (
    <FullCalendar
      plugins={[
        listPlugin,
        timeGridPlugin,
        dayGridPlugin,
        interactionPlugin,
      ]}
      headerToolbar={{
        left: 'prev,next',
        center: 'title',
        // right: '',
        right: 'resourceTimeGridDay,resourceTimeGridWeek',
      }}
      initialDate={moment('08:00', 'HH:mm').weekday(0).format('YYYY-MM-DD')}
      noEventsClassNames="no-event"
      eventMouseEnter={({ el }) => el.style.cursor = 'pointer'}
      initialView={'timeGridDay'}
      views={{
        resourceTimeGridDay: {
          type: 'timeGridDay',
          buttonText: 'Vue jour'
        },
        resourceTimeGridWeek: {
          type: 'timeGridWeek',
          buttonText: 'Vue semaine'
        }
      }}
      // visibleRange={{
      //   start: '2021-09-13',
      //   end: '2021-09-19',
      // }}
      // initialView={editing && isAdmin() ? 'timeGridWeek' : 'listWeek'}
      // duration={3}
      allDaySlot={false}
      datesSet={({
        startStr,
        endStr
      }) => updateFilters({
        from: startStr.split('T')[0],
        to: endStr.split('T')[0],
      })}
      slotMinTime='08:00'
      slotMaxTime='21:30'
      // dayCount={2}
      // height={650}
      locale="fr"
      firstDay={1} //Monday
      editable={editing && isAdmin()}
      selectable={false}
      selectMirror={true}
      windowResizeDelay={300}
      droppable={editing && isAdmin()}
      // allDaySlot={false} //Remove all day slot
      weekends={true} //Allow weekends
      events={events}
      eventReceive={modifEvent}
      eventDrop={modifEvent}
      eventResize={modifEvent}
      eventClick={({ event }) => {
        getLessonSlot({ id: event.id });
      }}
    />
  );
};
