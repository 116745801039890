import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

import { Layout } from './common/Layout';
import { AppContext } from '../context/AppContext';
import { querySchoolBookings } from '../utils/gqlQueries';
import { Loading } from './common/Loading';
import { SuccessfullBookingDialog } from './common/SuccesfullBookingDialog';
import { BookingItem } from './bookings/BookingItem';


export const Bookings = () => {
  const { fetchApi, golf } = useContext(AppContext);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const successParam = urlParams.get('success');
    if (successParam) {
      setSuccess(true);
      handleClickOpen();
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getBookings = () => {
    setLoading(true);
    fetchApi(querySchoolBookings, { golfId: golf.id })
      .then(response => {
        setLoading(false);
        if (!response) return;
        setBookings(response.data.getSchoolBookings.filter((booking) => booking.valid));
      });
  };

  useEffect(getBookings, []);

  return (
    <Layout>
      <Typography variant="h4">Mes inscriptions</Typography>
      {success && <SuccessfullBookingDialog open={open} onClose={() => setOpen(false)} />}
      {loading ? <Loading /> : (
        <>
          {bookings && bookings.map(booking => (
            <BookingItem booking={booking} key={booking.id} />
          ))}
        </>
      )}
    </Layout>
  );
};
