import React from 'react';
import { List } from '@material-ui/core';

import { NavLink } from './NavLink';


export const NavMenu = ({ routes }) => (
  <List>
    {routes.map(({ path, ...route }) => (
      <NavLink key={path} url={`${path}`} {...route} />
    ))}
  </List>
);
