import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Layout } from './common/Layout';
import { Filters } from './mercato/Filters';
import { AppContext } from '../context/AppContext';
import {
  queryLessonSlotStudents,
  mutateLessonSlotStudents,
} from '../utils/gqlQueries';

/* */
const useStyles = makeStyles((theme) => ({
  paper: {
    // width: 200,
    // height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export const AdminLessonSlots = () => {
  const classes = useStyles();

  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [checked, setChecked] = useState([]);

  const { fetchApi } = useContext(AppContext);
  const [rightLesson, setRightLesson] = useState(null);
  const [leftLesson, setLeftLesson] = useState(null);
  const [students, setStudents] = useState([]);

  // const [rightStudents, setLeftStudents] = useState([]);
  // const [rightStudents, setRightStudents] = useState([]);
  // const [unregisteredStudents, setUnregisteredStudents] = useState([]);

  /* */
  useEffect(() => {
    if (!leftLesson) return;

    // console.log(selectedLesson);
    fetchApi(queryLessonSlotStudents, ({
      ids: [leftLesson.nextSlot],
    })).then((response) => {
      // console.log(response);
      if (!response || !response.data) return;
      const res = response.data.getSchoolLessonSlotStudents;
      // console.log('left', res);
      // setLeftStudents(res);
      setLeft(res.map((s) => s.id));
      setStudents([...students, ...res]);
    });
  }, [leftLesson])

  /* */
  useEffect(() => {
    if (!rightLesson) return;

    // console.log(selectedLesson);
    fetchApi(queryLessonSlotStudents, ({
      ids: [rightLesson.nextSlot],
    })).then((response) => {
      // console.log(response);
      if (!response || !response.data) return;
      const res = response.data.getSchoolLessonSlotStudents;
      // console.log('right', res);
      // setRightStudents(res);
      setRight(res.map((s) => s.id));
      setStudents([...students, ...res]);
    });
  }, [rightLesson])

  /* */
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  /* */
  const handleCheckedRight = () => {
    // console.log('checked', checked);
    // console.log('left checked', leftChecked);

    fetchApi(mutateLessonSlotStudents, ({
      id: rightLesson.nextSlot,
      salesOrderLineSchoolIds: leftChecked,
    })).then((response) => {
      // console.log(response);
      if (!response || !response.data) return;
      // console.log('right', res);
      setRight(right.concat(leftChecked))
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    });
  };

  /* */
  const handleCheckedLeft = () => {
    // console.log('right checked', rightChecked);

    fetchApi(mutateLessonSlotStudents, ({
      id: leftLesson.nextSlot,
      salesOrderLineSchoolIds: rightChecked,
    })).then((response) => {
      // console.log(response);
      if (!response || !response.data) return;
      // console.log('right', res);
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    });
  };

  /* */
  const customList = (list) => {
    return (
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {list.map((id) => {
            const labelId = `transfer-list-item-${id}-label`;
            const student = students.find((s) => s.id === id);

            if (!student) {
              return (
                <ListItem key={id} role="listitem" button onClick={handleToggle(id)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${id}`} />
                </ListItem>
              );
            }

            return (
              <ListItem key={id} role="listitem" button onClick={handleToggle(id)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${student.lastname} ${student.firstname}`} />
              </ListItem>
            );
          })}
          {/* list.length === 0 && (
            <ListItem>
              <ListItemText primary="Aucun inscrit." />
            </ListItem>
          ) */}
        </List>
      </Paper>
    );
  };

  /* */
  const handleChangeLeft = (lesson) => {
    // console.log(lesson);
    setLeft([]);
    // setLeftStudents([]);
    setLeftLesson(lesson);
  }

  /* */
  const handleChangeRight = (lesson) => {
    // console.log(lesson);
    setRight([]);
    // setRightStudents([]);
    setRightLesson(lesson);
  }

  /* */
  const showUnregistered = () => {
    // console.log('left', left);
    // console.log('right', right);
    // console.log('checked', checked);
    setLeft([]);
    setLeftLesson({
      id: null,
      nextSlot: null,
      title: 'Liste des non inscrits',
    });

    fetchApi(queryLessonSlotStudents, ({
      ids: [null],
    })).then((response) => {
      // console.log(response);
      if (!response || !response.data) return;
      const res = response.data.getSchoolLessonSlotStudents;
      // console.log('unregistered', res);
      // setRightStudents(res);
      setLeft(res.map((s) => s.id));
      setStudents([...students, ...res]);
    });
  }

  return (
    <Layout>
      <Typography variant="h4">
        {'Mercato'}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={5}>
          <Filters onChange={handleChangeLeft} />
          <Button variant="contained" size="small" color="primary" onClick={showUnregistered}>
            {'Afficher les non inscrits'}
          </Button>
        </Grid>
        <Grid item md={2}>
          &nbsp;
        </Grid>
        <Grid item xs={5}>
          <Filters onChange={handleChangeRight} />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={5}>
          {leftLesson && (
            <>
              <Typography variant="h5" gutterBottom>{leftLesson.title}</Typography>
              {leftLesson.id && (
                <>
                  <Typography>{left.length} / {leftLesson.places}</Typography>
                  {left.length > leftLesson.places && (
                    <Typography color="error">
                      {`Capacité dépassée : ${left.length - leftLesson.places} de plus !`}
                    </Typography>
                  )}
                </>
              )}
              {customList(left)}
            </>
          )}
        </Grid>
        <Grid item md={2}>
          <Grid container direction="column" alignItems="center">
            {leftLesson
              && rightLesson
              && leftLesson.id !== rightLesson.id
              && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0 || !rightLesson}
                    aria-label="move selected right">
                    {'>'}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0 || !leftLesson}
                    aria-label="move selected left">
                    {'<'}
                  </Button>
                </>
              )}
          </Grid>
        </Grid>
        <Grid item xs={5}>
          {rightLesson && (
            <>
              <Typography variant="h5" gutterBottom>{rightLesson.title}</Typography>
              <Typography>{right.length} / {rightLesson.places}</Typography>
              {right.length > rightLesson.places && (
                <Typography color="error">
                  {`Capacité dépassée : ${right.length - rightLesson.places} de plus !`}
                </Typography>
              )}
              {customList(right)}
            </>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}
