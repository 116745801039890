import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';


export const SimpleWidget = ({ children, link, linkLabel, externalLink, ...props }) => {

  const classes = useStyles(props);

  const AppLink = ({ children, to, ...p }) => externalLink
    ? <a href={to} rel='noreferrer' target="_blank" {...p}>{children}</a>
    : <Link to={to} {...p}>{children}</Link>;

  return (
    <div className={classes.container}>
      {props.image && <div className={classes.image} />}
      {children}
      {link &&
        <AppLink to={link} className={classes.action}>
          <Button variant="outlined">{linkLabel || 'Voir'}</Button>
        </AppLink>
      }
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 25,
  },
  action: {
    textDecoration: 'none',
    color: 'inherit',
    position: 'absolute',
    bottom: 15,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  image: ({ image }) => ({
    background: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: theme.spacing(18),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
  })
}));
