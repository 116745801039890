import React, { useContext } from 'react';
import { ListItem, ListItemAvatar, Avatar, ListItemText, Box, Chip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';
import Image from '../../assets/avatar_temp.jpg';
import clsx from 'clsx';
import moment from 'moment';
import { PlanningContext } from '../../context/PlanningContext';
import InfoIcon from '@material-ui/icons/Info';
import {
  days,
} from '../../utils/filters';
import { formatTime } from '../../utils/date';
import './../../theme/theme.css'


export const LessonsItem = ({ title, slot, teacher, onClick, id }) => {

  const classes = useStyles();
  const { palette } = useTheme();
  const { lessonSlots, getLessonSlot } = useContext(PlanningContext);
  const dayOfWeek = moment(slot.date, 'x').format('d');
  const Xday = getLabel(days, parseInt(dayOfWeek));
  const Xtime = formatTime(slot.startHour);
  if (!lessonSlots) return null;
  const { valid, recurrent } = lessonSlots?.filter(ls => ls.id === slot.id)[0] || { valid: null, recurrent: null };
  
  function getLabel(filters, value) {
    const found = filters.find((o) => value === o.value);
  
    return (found ? found.label : 'Tout public');
  }

  return (
    <div className={clsx('lesson', 'draggable', classes.draggable, recurrent ? 'draggable-recurrent' : '')} data-id={id} data-title={title} style={{ padding: 3 }}>
      <ListItem className={classes.root} style={{
        background: !valid ? palette.eventColor['needValid'] : palette.eventColor[recurrent ? 'reccurent' : 'occasionnel'],
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: !valid ? 8 : 0,
      }}>
        <Box style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%' 
        }} onClick={onClick}>
          <ListItemAvatar>
            <Avatar alt={teacher} src={Image} />
          </ListItemAvatar>
          <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ListItemText primary={title} secondary={teacher} color="white" style={{ marginBottom: 0 }} />
            <ListItemText primary={`${Xday} - ${Xtime}`} color="white" style={{ marginTop: 0 }} />
          
          </Box>
          <MenuIcon edge="end" />
        </Box>
        {!valid && (
          <Chip
            onClick={() => getLessonSlot({ id: slot.id })}
            size="small"
            label="Non validé"
            icon={<InfoIcon />}
            color="secondary"
            style={{ alignSelf: 'flex-start', cursor: 'pointer', marginTop: 4 }}
          />
        )}
      </ListItem>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    borderRadius: theme.shape.borderRadius,
    witdh: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  draggable: {
    cursor: 'grab',
  },
  fixed: {
    cursor: 'pointer',
  },
}));
