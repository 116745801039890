import React, { useContext } from 'react';
import { Avatar, Grid, Paper, Typography, Box, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import { PlanningContext } from '../../context/PlanningContext';


export const LessonSlotTeacherPrice = () => {

  const classes = useStyles();
  const { lessonSlot } = useContext(PlanningContext);
  const image = 'https://images.unsplash.com/flagged/photo-1558759103-d4d02c7ee13f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=634&q=80'

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container justify="center" alignItems="center" spacing={5}>
        <Grid item>
          <Avatar alt={lessonSlot.teacher} src={image} className={classes.avatar} />
        </Grid>

        <Grid item>
          <Grid container direction="column" justify="center" alignItems="flex-start">
            <Typography variant="caption">Votre entraineur:</Typography>
            <Typography variant="h6">{lessonSlot.teacher}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container direction="row" justify="space-around" alignItems="center">
        <Typography variant="h6">Tarif {lessonSlot.recurrent ? `à l'année` : 'de la séance'}</Typography>
        <Typography variant="h4" color='primary'>
          <Box fontWeight="fontWeightBold" >{(lessonSlot.displayPrice || lessonSlot.price).toFixed(2)} €</Box></Typography>
      </Grid>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: theme.spacing(1, 1),
    boxShadow: theme.shadows[1],
  },
  paper: {
    marginBlock: theme.spacing(3),
    padding: theme.spacing(1),
    textAlign: 'left',
    width: '100%',
    border: '2px #EFEFEF solid'
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));
