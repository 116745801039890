import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { AppContext } from '../context/AppContext';


export const PageNotFound = () => {

  const classes = useStyles();
  const { golf } = useContext(AppContext);
  const url = 'https://www.liveabout.com/thmb/oEofxvpfuWyCswSrmm-RoVoYtxA=/3606x2028/smart/filters:no_upscale()/searching-for-lost-golf-ball-582b38125f9b58d5b11ce6e1.jpg'

  return (
    <Grid container justify="center">
      <Paper className={classes.paper}>
        <div data-testid="notfound">
          <img alt='404' src={url} className={classes.image} />
          <Typography variant="h3" className={classes.title}>Ouuups, vous êtes hors du parcours</Typography>
          <Typography variant="h5" className={classes.text}>Désolé, nous donnons tout, mais nous n'arrivons pas à trouver la page que vous êtes en train de chercher !</Typography>
          {
            golf &&
            <Link to={`/${golf.slug}/dashboard`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button variant="contained" color="primary" className={classes.button}>Retourner vers le dashboard</Button>
            </Link>
          }
        </div>
      </Paper>
    </Grid>

  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: theme.spacing(100),
    textAlign: 'center',
    margin: theme.spacing(10)
  },
  image: {
    width: theme.spacing(100),
  },
  button: {
    margin: theme.spacing(3),
  },
  title: {
    margin: theme.spacing(3, 0),
  },
  text: {
    margin: theme.spacing(5, 3),
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));
