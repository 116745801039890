import React from 'react';
import { Grid, Typography } from '@material-ui/core';


export const BookingDate = ({ icon, text }) => (
  <Typography variant="subtitle2">
    <Grid container direction="row" alignItems="center" justify="center">
      {icon}
      {text}
    </Grid>
  </Typography>
);
