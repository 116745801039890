import React from 'react';
import {
  Dashboard as Ds,
  DateRange,
  // GolfCourse,
  // Group,
  // Person,
  PlaylistAddCheck,
  School,
  SupervisorAccount,
  // AssignmentInd,
  SyncAlt,
  ViewList,
  Settings,
} from '@material-ui/icons';

import { Dashboard } from './components/Dashboard';
import { Ecole } from './components/Ecole';
// import { Cours } from './components/Cours';
import { Teachers } from './components/Teachers';
import { Bookings } from './components/Bookings';
// import { SignUpAdmin } from './components/SignUpAdmin';
import Login from './components/Login';
// import { CreditCard } from './components/CreditCard';
import SignUp from './components/SignUp';
import { Payment } from './components/Payment';
// import { Profile } from './components/Profile';
import { AdminLessons } from './components/AdminLessons';
// import { AdminTeachers } from './components/AdminTeachers';
import { AdminLessonsList } from './components/AdminLessonsList';
import { AdminLessonSlots } from './components/AdminLessonSlots';
import { AdminSettings } from './components/AdminSettings';
import Forgot from './components/Forgot';


const publicRoutes = [{
  path: '',
  page: Login,
  exact: true,
}, {
  path: 'signup',
  page: SignUp,
}, {
  path: 'forgot',
  page: Forgot,
}];

const restrictedRoutes = [
  {
    name: 'Dashboard',
    path: `dashboard`,
    icon: <Ds />,
    page: Dashboard,
    exact: true,
  },
  {
    name: 'École de golf',
    path: `ecole`,
    icon: <School />,
    page: Ecole,
  },
  // {
  //   name: 'Nos cours à la carte',
  //   path: `cours`,
  //   icon: <GolfCourse />,
  //   page: Cours,
  // },
  {
    name: 'Nos professeurs',
    path: `teachers`,
    icon: <SupervisorAccount />,
    page: Teachers,
  },
  {
    name: 'Mes inscriptions',
    path: `bookings`,
    icon: <PlaylistAddCheck />,
    page: Bookings,
  },
  // {
  //   name: 'Mon profil',
  //   path: `profile`,
  //   icon: <Person />,
  //   page: Profile,
  // },
  // {
  //   name: 'Ma carte bancaire',
  //   path: `credit-card`,
  //   icon: <Person />,
  //   page: CreditCard,
  // }
];

const hiddenRestrictedRoutes = [{
  path: 'payment',
  page: Payment,
}];

const adminRoutes = [
  // {
  //   name: 'Gestion des utilisateurs',
  //   path: `signupadmin`,
  //   icon: <Group />,
  //   page: SignUpAdmin,
  // },
  {
    name: 'Gestion des cours',
    path: `lessons`,
    icon: <DateRange />,
    page: AdminLessons,
  },
  // {
  //   name: 'Gestion des professeurs',
  //   path: `teachers-list`,
  //   icon: <AssignmentInd />,
  //   page: AdminTeachers,
  // },
  {
    name: 'Liste des cours',
    path: `lessons-list`,
    icon: <ViewList />,
    page: AdminLessonsList,
  },
  {
    name: 'Mercato',
    path: `lesson-slots`,
    icon: <SyncAlt />,
    page: AdminLessonSlots,
  },
  {
    name: 'Paramètres',
    path: `settings`,
    icon: <Settings />,
    page: AdminSettings,
  },
];

export {
  publicRoutes,
  restrictedRoutes,
  hiddenRestrictedRoutes,
  adminRoutes,
};
