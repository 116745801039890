import React from 'react';
import {
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* */
const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
});

/* */
class LoginForm extends React.Component {
  /* */
  render() {
    const {
      errors,
      values,
      classes,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <form className="form">
        <TextField
          required
          fullWidth
          autoComplete='username'
          name='email'
          type='email'
          label='Adresse email'
          variant='outlined'
          onBlur={handleBlur}
          value={values.email}
          onChange={handleChange}
          error={!!(errors.email)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          required
          fullWidth
          autoComplete='current-password'
          name='password'
          type='password'
          variant='outlined'
          label='Mot de passe'
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password}
          error={!!(errors.password)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyUp={e => e.keyCode === 13 && handleSubmit.call()}
        />
        <Button
          fullWidth
          size="large"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          variant="contained">
          {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Se connecter'}
        </Button>
      </form>
    );
  }
}

export default withStyles(styles)(LoginForm);
