import React, { createContext, useContext, useEffect, useState } from 'react';

import { AppContext } from './AppContext';
import {
  queryLockPlace,
  queryUnlockPlace,
  querySchoolLessons,
  querySchoolLessonSlot,
  querySchoolLessonSlots,
  queryGetUserLockStatus,
} from '../utils/gqlQueries';
import {
  ages,
  days,
  levels,
  recurrencies,
  prepareFilters,
} from '../utils/filters';


let emptyLesson = {
  title: '',
  price: 0,
  teachPrice: 0,
  age: 'adult',
  //level : '',
  description: '',
  pgbNumcotis: '',
  places: 8,
  recurrent: false
};

export const PlanningContext = createContext();

export const PlanningProvider = ({ children }) => {

  const { golf, fetchApi } = useContext(AppContext);
  const [lessons, setLessons] = useState([]);
  const [lessonSlots, setLessonSlots] = useState([]);
  const [lesson, setLesson] = useState(null);
  const [lessonSlot, setLessonSlot] = useState(null);
  const [filters, setFilters] = useState({
    teachers: [],
    age: ages[0].value,
    day: days[0].value,
    level: levels[0].value,
    recurrent: recurrencies[0].value,
  });

  useEffect(() => {
    //   console.log(filters);
    //   if (!filters.recurrent) {
    if (filters.to && filters.from) {
      getLessonSlots();
    }
    //   }
  }, [filters]);

  const updateFilters = f => {
    return setFilters({
      ...filters,
      ...f,
      golfId: golf.id,
    })
  };

  const newLesson = (recurrent) => {
    if (recurrent) {
      setLesson({
        ...emptyLesson,
        recurrent: true,
      });
    } else {
      setLesson({
        ...emptyLesson,
      })
    }
  };
  const clearLesson = () => setLesson(null);
  const updateLesson = (key, value) => {
    if (typeof key === 'string')
      return setLesson({
        ...lesson,
        [key]: value,
      })
    return setLesson({ ...lesson, ...key })
  };

  const getLessons = (recurrent = 1) => fetchApi(querySchoolLessons, ({
    golfId: golf.id,
    ...prepareFilters(filters, true),
    recurrent,
  })).then((response) => {
    if (!response || !response.data) return;
    const l = response.data.getSchoolLessons.reduce((acc, sum, ind) => {
      acc[ind] = {
        ...sum,
        teachPrice: +sum.displayPrice - +sum.price,
      };
      return acc;
    }, []);
    setLessons(l);
    return l;
  });

  const clearLessonSlot = () => setLessonSlot(null);
  const updateLessonSlot = (key, value) => setLessonSlot({ ...lessonSlot, [key]: value });
  const getLessonSlot = (id) => {
    return fetchApi(querySchoolLessonSlot, id)
      .then(response => {
        if (!response) return;
        setLessonSlot(response.data?.getSchoolLessonSlot || []);
      });
  };

  const getLessonSlots = (recurrent = 1) => {
    if (!filters.golfId) return;
    return fetchApi(querySchoolLessonSlots, {
      ...prepareFilters(filters),
      recurrent,
      all: true,
    })
      .then(response => {
        if (!response || !response.data) return;
        setLessonSlots(response.data.getSchoolLessonSlots);
      });
  };

  const getUserLockStatus = ({ id }) => fetchApi(queryGetUserLockStatus, {
    id
  }).then(res => res.data.getUserLockStatus)

  const lockLessonPlace = ({ id }) => fetchApi(queryLockPlace, {
    id
  })

  const unLockLessonPlace = ({ id }) => fetchApi(queryUnlockPlace, {
    id
  })

  return (
    <PlanningContext.Provider value={{
      lessons,
      lesson,
      setLesson,
      newLesson,
      clearLesson,
      getUserLockStatus,
      lockLessonPlace,
      unLockLessonPlace,
      updateLesson,
      getLessons,
      lessonSlots,
      lessonSlot,
      setLessonSlot,
      clearLessonSlot,
      updateLessonSlot,
      getLessonSlot,
      getLessonSlots,
      filters,
      updateFilters,
    }}>
      {children}
    </PlanningContext.Provider>
  );
}
