import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';


export const LessonSlotDataItem = ({ icon, text, title }) => {
  const classes = useStyles();
  return (
    <Grid item xs={6} sm={3} className={classes.item}>
      <Grid container direction="column" justify="center" alignItems="center">
        {icon}
        <Typography variant="h5">
          <Box fontWeight="fontWeightBold">
            {text}
          </Box></Typography>
        <Typography variant="body1">{title.toUpperCase()}</Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2),
  }
}));