import React from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  Link,
  Paper,
  Avatar,
  Typography,
} from '@material-ui/core';
import ForgotPasswordForm from './common/ForgotPasswordForm';
import { createValidationSchema, validators } from '../utils/validation';
import { AppContext } from '../context/AppContext';
import Alert from './common/Alert';

/* */
const validationSchema = createValidationSchema({
  email: validators.email,
});

/* */
const styles = theme => ({
  container: {
    width: '30%',
    minWidth: '300px',
    margin: '20% auto',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(4),
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  avatar: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
});

/* */
class Forgot extends React.Component {

  static contextType = AppContext;

  /* */
  state = {
    error: null,
    success: null,
  };

  /* */
  onSubmit = (values, { setSubmitting }) => {
    const { golf } = this.context;
    this.setState({
      error: null,
      success: null,
    });

    fetch(`${process.env.REACT_APP_AUTH_URL}/password/forgot`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: values.email,
        golfId: golf.id,
      })
    }).then(r => r.json())
      .then((r) => {
        // console.log(r);
        if (r.status === 'success') {
          this.setState({
            success: `Un email a été envoyé à l'adresse ${values.email}`,
          });
        } else {
          if (r.data.error === 'errors.user.not_found') {
            this.setState({
              error: 'Adresse email inconnue',
            });
          } else {
            this.setState({
              error: 'Une erreur s\'est produite',
            });
          }
        }
      })
      .catch((error) => {
        console.error('err:', error);
        this.setState({
          error: error.message,
        });

      })
      .finally(() => setSubmitting(false));
  };

  /* */
  render() {
    const { classes } = this.props;
    const { error, success } = this.state;
    const { golf } = this.context;

    return (
      <div className={classes.container}>
        <Typography component="h1" variant="h5" align="center">
          {golf && golf.name}
        </Typography>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography gutterBottom component="h2" variant="h5">
            {'Mot de passe oublié'}
          </Typography>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
            component={ForgotPasswordForm} />
        </Paper>
        <Box textAlign="center">
          {error ? (
            <Alert severity="error" autohide position="absolute">{error}</Alert>
          ) : (null)}
          {success ? (
            <Alert severity="success" autohide position="absolute">{success}</Alert>
          ) : (null)}
          <Link
            to={`/${golf.slug}/`}
            color="textSecondary"
            component={RouterLink}>
            {'Se connecter'}
          </Link>
        </Box>
      </div>
    );
  }
}

export default withStyles(styles)(Forgot);
