import React, { useContext, useEffect, useState } from 'react';
import { Grid, Hidden, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Layout } from './common/Layout';
import { Filters } from './planning/Filters';
import { PlanningContext } from '../context/PlanningContext';
import { LessonCard } from './planning/LessonCard';
import { LessonSlotDialog } from './planning/LessonSlotDialog';
import { FilterList } from '@material-ui/icons';
import { Dialog } from './common/Dialog';
import moment from 'moment';
import { AppContext } from '../context/AppContext';
import { queryGetSettings } from '../utils/gqlQueries';
import Alert from './common/Alert';


export const Ecole = () => {
  const { golf, fetchApi } = useContext(AppContext);
  const { getLessons, filters, clearLessonSlot } = useContext(PlanningContext);
  const [bookingActif, setBookingActif] = useState(true);
  const [loading, setLoading] = useState(true);
  const [lessons, setLessons] = useState([]);
  const [showFiltersDialog, setShowFiltersDialog] = useState(false);
  const classes = useStyles();
  const getSettings = (golfId) => fetchApi(queryGetSettings, {
    golfId,
  }).then(res => res?.data?.getEDGSettings);

  useEffect(() => clearLessonSlot(), []); // close dialog
  useEffect(async () => {
    setLessons(await getLessons(1));
    getSettings(golf.id).then(res => {
      setBookingActif(res.booking);
      setLoading(false);
    })
  }, []);
  useEffect(async () => {
    const timer = setTimeout(async () => {
      setLessons(await getLessons(1))
    }, 5000);
    return () => clearTimeout(timer);
  }, [filters, lessons]);

  return (
    <Layout>
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.spacer}>
        <Typography variant="h4">École de golf</Typography>
        <Hidden mdUp implementation="css">
          <Button variant="outlined" onClick={() => setShowFiltersDialog(true)} startIcon={<FilterList />}>Filtrer par</Button>
        </Hidden>
      </Grid>

      <Hidden smDown>
        <Filters />
      </Hidden>

      <Grid className={`${classes.spacer} ${!bookingActif ? '' : classes.hidden}`}>
        <Alert className={classes.alert} severity="error">{'Veuillez vous rapprocher de l’accueil du golf pour vous inscrire'}</Alert>
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
        {lessons && lessons.sort(function(a,b){
          return moment(moment(a.slot.date, 'x').format('d') + '-' + a.slot.startHour, 'd-HH:mm:ss') - moment(moment(b.slot.date, 'x').format('d') + '-' + b.slot.startHour, 'd-HH:mm:ss');
        }).filter((l) => l.studentsCount < l.places).map((item, index) => (
          <LessonCard lesson={item} key={index} disabled={loading || !bookingActif} />
        ))}
      </Grid>
      <LessonSlotDialog />
      {showFiltersDialog ? (
        <Dialog
          onClose={() => setShowFiltersDialog(false)}
          open={showFiltersDialog}
          title={'Filtres'}
        >
          <Filters />
        </Dialog>
      ) : (null)}
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    marginBottom: theme.spacing(3),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  hidden: {
    display: 'none !important',
  },
}));
