import React from 'react';
import { Grid } from '@material-ui/core';

import { Layout } from './common/Layout';
import { Admin } from './common/Admin';
import { WelcomeBanner } from './dashboard/WelcomeBanner';
import { WeatherWidget } from './dashboard/WeatherWidget';
import { Widget } from './dashboard/Widget';
import { EcoleWidget } from './dashboard/EcoleWidget';


export const Dashboard = () => (
  <Layout>

    <WelcomeBanner />

    <Grid container direction="row" spacing={3}>
      <Admin hidden>
        <Widget title="Ecole de golf" Comp={EcoleWidget} />
        <Widget title="La météo du jour" Comp={WeatherWidget} background={['#7298F6', '#303f9f']} white />
      </Admin>
    </Grid>

  </Layout>
);
