import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog as MuiDialog, DialogContent, DialogTitle } from '@material-ui/core';


export const Dialog = ({
  open,
  onClose,
  title,
  children
}) => {

  const classes = useStyles();

  return (
    <MuiDialog
      onClose={onClose}
      open={open}
      fullWidth={true}>

      <DialogTitle id="form-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent className={classes.container}>
        {children}
      </DialogContent>

    </MuiDialog>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
}));
