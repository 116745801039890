import React, { useContext } from 'react';

import { PlanningContext } from '../../context/PlanningContext';
import { Dialog } from '../common/Dialog';
import { LessonSlot } from './LessonSlot';


export const LessonSlotDialog = () => {

  const { lessonSlot, clearLessonSlot } = useContext(PlanningContext);

  return (
    <Dialog
      title={lessonSlot && lessonSlot.lesson}
      onClose={clearLessonSlot}
      open={!!lessonSlot}>
      <LessonSlot
        lessonSlot={lessonSlot}
        onClose={clearLessonSlot} />
    </Dialog>
  );
};
