import React, { useContext, useEffect, useState } from 'react';
import { DateRange, Event } from '@material-ui/icons';

import { AppContext } from '../../context/AppContext';
import { Form } from '../common/Form';
import { PlanningContext } from '../../context/PlanningContext'
import { BookingContext } from '../../context/BookingContext'
import { mutateLesson, mutateLessonSlot } from '../../utils/gqlQueries';
import { Loading } from '../common/Loading';
import { ages, levels } from '../../utils/filters';
import moment from 'moment';


export const LessonForm = ({ lesson, onSubmit, onClose }) => {

  const { teachers, fetchApi } = useContext(AppContext);
  const { updateLesson, getLessonSlots } = useContext(PlanningContext);
  const { numcotisList } = useContext(BookingContext);
  const [initialLesson, setInitialLesson] = useState(null);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const [recurrence, setRecurrence] = useState({
    date: moment('08:00', 'HH:mm').weekday(0).format('YYYY-MM-DD'),
    time: '08:00',
    times: 1,
    everyYears: 0,
    everyMonths: 0,
    everyDays: 0,
    everyHours: 0,
    everyMinutes: 0,
  });

  const [slot, setSlot] = useState({
    startHour: '08:00',
    endHour: '10:00',
  });

  const isModif = () => lesson && !!lesson.id;

  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    const { teacher, ...l } = lesson;
    if (isModif()) delete l.recurrent;

    if (l.price === 0) {
      setLoading(false);
      return setError('Le prix est à 0€');
    }

    if (isModif() && initialLesson) {
      Object.keys(l).forEach(property => {
        if (property !== 'id' && l[property] === initialLesson[property]) {
          delete l[property];
        }
      });
    }

    if (Object.keys(l).length > 1) {
      fetchApi(mutateLesson(isModif()), l).then(response => {
        // console.log(response);

        if (!isModif() && l.recurrent) {
          const id = response.data.createLesson.id;
          fetchApi(mutateLessonSlot(isModif()), {
            lessonId: id,
            date: recurrence.date,
            startHour: slot.startHour,
            endHour: slot.endHour,
          }).then(({ data }) => {
            // const { createLessonSlot } = data;
            getLessonSlots();
            // console.log(createLessonSlot);
          }).catch(e => {
            console.error(e);
          });
        }

        setLoading(false);
        if (response.errors) {
          return setError(response.errors[0].message);
        }
        onSubmit();
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInitialLesson(lesson);
  }, [])

  if (!lesson) {
    return <Loading />
  }

  const recurrentOnlyFields = [];

  if (!isModif() && lesson.recurrent && false) {
    recurrentOnlyFields.push({
      type: 'date',
      required: true,
      label: 'À partir du (date)',
      name: 'date',
      InputLabelProps: { shrink: true },
      onCustomChange: (a) => {
        setRecurrence((prev) => {
          return {
            ...prev,
            date: a.target.value,
          }
        })
      },
      value: recurrence.date,
    }, {
      type: 'time',
      required: true,
      label: 'De',
      name: 'startHour',
      InputLabelProps: { shrink: true },
      onCustomChange: (a) => {
        setSlot((prev) => {
          return {
            ...prev,
            startHour: a.target.value,
          }
        })
      },
      value: slot.startHour,
    }, {
      type: 'time',
      required: true,
      label: 'À',
      name: 'endHour',
      InputLabelProps: { shrink: true },
      onCustomChange: (a) => {
        setSlot((prev) => {
          return {
            ...prev,
            endHour: a.target.value,
          }
        })
      },
      value: slot.endHour,
    })
  } else {
    delete lesson.recurrence;
  }
  return (
    <Form
      error={error}
      btnLabel={isModif() ? 'Modifier un cours' : 'Créer un cours'}
      onClose={onClose}
      loading={loading}
      onSubmit={submitForm}
      onChange={updateLesson}
      fields={[
        {
          type: 'toggle',
          required: true,
          label: 'Récurrence',
          name: 'recurrent',
          disabled: isModif(),
          value: true,
          values: {
            on: {
              label: 'Cours récurrent',
              icon: <DateRange />,
              disabled: true
            },
            off: {
              label: 'Cours unique',
              icon: <Event />,
              disabled: true
            }
          }
        },
        {
          required: true,
          label: 'Titre',
          name: 'title',
          value: lesson.title || ''
        },
        {
          required: false,
          label: 'Description',
          name: 'description',
          value: lesson.description || ''
        },
        {
          required: true,
          label: 'Professeur',
          name: 'teacherId',
          value: lesson.teacherId || '',
          items: teachers.map((teacher) => (
            {
              label: `${teacher.firstname} ${teacher.lastname}`,
              value: teacher.id
            }
          )),
          type: 'select'
        },
        {
          required: true,
          label: 'Nombre de places',
          name: 'places',
          value: +lesson.places || '',
          type: 'number'
        },
        ...recurrentOnlyFields,
        {
          required: true,
          label: 'Prix du cours',
          name: 'price',
          value: +lesson.price || '',
          type: 'number'
        },
        {
          required: false,
          label: 'Prix du professeur',
          name: 'teachPrice',
          value: +lesson.teachPrice || '',
          type: 'number'
        },
        {
          required: true,
          label: 'Niveau de jeu',
          name: 'level',
          value: lesson.level || '',
          items: levels.slice(1),
          type: 'select'
        },
        {
          required: true,
          label: 'Age requis',
          name: 'age',
          value: lesson.age || '',
          items: ages.slice(1),
          type: 'select',
        },
        {
          required: true,
          label: 'Type de cotisation',
          name: 'pgbNumcotis',
          value: lesson.pgbNumcotis || '',
          items: numcotisList,
          type: 'select',
        }
      ]}>
    </Form>
  );
};
