import React from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Redirect } from 'react-router-dom';
import {
  Link,
  Paper,
  Avatar,
  Typography,
  Button,
} from '@material-ui/core';
import LoginForm from './common/LoginForm';
import { createValidationSchema, validators } from '../utils/validation';
import { AppContext } from '../context/AppContext';
import Alert from './common/Alert';

/* */
const validationSchema = createValidationSchema({
  email: validators.email,
  password: validators.unsecuredPassword,
});

/* */
const styles = theme => ({
  container: {
    width: '30%',
    minWidth: '300px',
    margin: '15% auto',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(4),
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  avatar: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
});

/* */
class Login extends React.Component {

  static contextType = AppContext;

  state = {
    signedIn: false,
    error: null,
    timer: null,
  }

  /* */
  onSubmit = (values, { setSubmitting }) => {
    const { signin } = this.context;

    this.setState({
      signedIn: false,
      error: null,
    });

    signin(values)
      .then((d) => {
        this.setState({
          timer: setTimeout(() => {
            setSubmitting(false);
            this.setState({ signedIn: true });
          }, 5000),
        });
      })
      .catch((error) => {
        if (error === 'errors.server.invalid_credentials') {
          this.setState({ error: 'Adresse email ou mot de passe incorrect' });
        } else {
          this.setState({ error: 'Une erreur s\'est produite' });
        }
        setSubmitting(false);
      });
  };

  componentWillUnmount = () => {
    const { timer } = this.state;
    if (timer) {
      clearTimeout(timer);
    }
  }

  /* */
  render() {
    const { classes } = this.props;
    const { user, loadingUser, golf } = this.context;
    const { signedIn, error } = this.state;

    if (user) { return <Redirect to={`/${golf.slug}/dashboard`} /> };

    return (
      <div className={classes.container}>
        <Typography component="h1" variant="h5" align="center">
          {golf && golf.name}
        </Typography>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography gutterBottom component="h2" variant="h5">
            {'Identification'}
          </Typography>
          <br />
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            component={LoginForm}
            onSubmit={this.onSubmit}
            validationSchema={validationSchema} />
        </Paper>
        <Box textAlign="center" mb={2}>
          <Typography>
            {'Vous êtes visiteur ?'}
          </Typography>
          <Link
            to={`/${golf.slug}/signup`}
            component={RouterLink}>
            <Button variant="contained" color="primary">
              {'Inscrivez-vous'}
            </Button>
          </Link>
        </Box>
        <Box textAlign="center">
          <Link
            to={`/${golf.slug}/forgot`}
            color="textSecondary"
            component={RouterLink}>
            {'Mot de passe oublié ?'}
          </Link>
        </Box>
        {signedIn && !loadingUser && !user ? (
          <Alert severity="error" autohide position="absolute">{'Une erreur s\'est produite.'}</Alert>
        ) : (null)}
        {error ? (
          <Alert severity="error" autohide position="absolute">{error}</Alert>
        ) : (null)}
      </div>
    );
  }
}

export default (withStyles(styles)(Login));
