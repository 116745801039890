import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { WeatherInformationItem } from './WeatherInformationItem';


export const WeatherSecond = ({ weather }) => {

  const classes = useStyles();
  const informations = [{
    id: 0,
    title: 'Vent',
    data: `${~~weather.wind_speed} km/h`
  }, {
    id: 1,
    title: 'Humidité',
    data: `${weather.humidity} %`
  }];

  return (
    <Grid container direction="row" justify="space-evenly" alignItems="center" className={classes.container}>
      {informations.map(info => (
        <Grid item key={info.id}>
          <WeatherInformationItem title={info.title} data={info.data} />
        </Grid>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2, 0)
  },
}));
