import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import { Button, Drawer, Hidden, Toolbar } from '@material-ui/core';
import { drawerWidth } from '../../utils/layout';

import { SidebarContent } from './SidebarContent';


export const AppSidebar = ({ open, closeDrawer, ...props }) => {

  const classes = useStyles(props);

  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    closeDrawer();
  };

  return (
    <>
      <Hidden lgUp implementation="css">
        <Drawer open={open} className={`${classes.drawer} ${classes.notPrintable}`} variant="temporary" anchor="left" classes={{ paper: classes.drawerPaper }} onClose={toggleDrawer()}>
          <div className={classes.drawerContainer}>
            <Button onClick={closeDrawer}
              className={classes.button}
              size="small"
              startIcon={<ChevronLeft />}>
              Réduire le menu
            </Button>
            <SidebarContent />
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer open={open} className={`${classes.drawer} ${classes.notPrintable}`} variant="permanent" anchor="left" classes={{ paper: classes.drawerPaper }} onClose={toggleDrawer()}>
          <div className={classes.drawerContainer}>
            <Toolbar />
            <SidebarContent />
          </div>
        </Drawer>
      </Hidden>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto'
  },
  button: {
    textTransform: 'none',
    margin: theme.spacing(1),
  },
  notPrintable: {
    '@media print': {
      display: 'none',
    },
  }
}));
