import { Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { AppContext } from '../../context/AppContext';
// import {
//   queryRecipientViewUrl,
//   verifySignatureFromAccountAndGolf,
// } from '../../utils/gqlQueries';
import { ButtonBar } from '../common/ButtonBar';
import { Form } from '../common/Form';
import { PlanningContext } from '../../context/PlanningContext';
import { genders } from '../../utils/filters';
import { formatToUS } from '../../utils/date';
import moment from 'moment';


export const LessonSlotBooking = ({ lessonSlot, setIsBooking }) => {
  const classes = useStyles();
  const { getLessonSlot, lessonSlot: freshDataLessonSlot, lessons, lockLessonPlace } = useContext(PlanningContext);

  const {
    user,
    golf,
    // fetchApi,
  } = useContext(AppContext);

  // Initialyze booking
  const [booking, setBooking] = useState({
    lessonSlotId: freshDataLessonSlot.id,
    lastname: user.lastname,
    firstname: user.firstname,
    address: user.address,
    address2: user.address2,
    postalCode: user.postalCode,
    city: user.city,
    gender: user.gender,
    phone: user.phone.replace(/\D/g, '').replace(/^33/, '0').trim(),
    // On force à retaper la date au bon format
    // birthdate: user.birthdate && moment(user.birthdate, 'x').format('YYYY-MM-DD'),
    birthdate: '',
    amount: freshDataLessonSlot.price,
    reservationOther: false,
    comment: ''
  });

  const [thirdParty, setThirdParty] = useState(false);
  const [disabled, setDisabled] = useState('left');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    // console.log(name, value);
    setBooking({
      ...booking,
      [name]: value
    });
  };

  const handleClick = (thirdParty) => {
    setDisabled(thirdParty ? 'right' : 'left');
    setThirdParty(thirdParty);
    setBooking({
      ...booking,
      lastname: !thirdParty ? user.lastname : '',
      firstname: !thirdParty ? user.firstname : '',
      gender: !thirdParty ? user.gender : '',
      address: !thirdParty ? user.address : '',
      address2: !thirdParty ? user.address2 : '',
      postalCode: !thirdParty ? user.postalCode : '',
      city: !thirdParty ? user.city : '',
      phone: !thirdParty ? user.phone.replace(/\D/g, '').replace(/^33/, '0').trim() : '',
      // birthdate: !thirdParty ? user.birthdate && moment(user.birthdate, 'x').format('YYYY-MM-DD') : '',
      birthdate: !thirdParty ? user.birthdate : '',
      reservationOther: thirdParty,
      comment: ''
    });
  };

  const handleClose = () => {
    setIsBooking(false);
  };

  const verifyAge = (birthdate, age) => {
    let min = null;
    let max = null;
    switch (age) {
    case 'baby':
      min = 3;
      max = 7;
      break;
    case 'kid1':
      min = 5;
      max = 10;
      break;
    case 'kid2':
      min = 9;
      max = 14;
      break;
    case 'kid3':
      min = 13;
      max = 18;
      break;
    // case 'kid4':
    //   min = 12;
    //   max = 18;
    //   break;
    case 'teenager1':
      max = 14;
      break;
    case 'teenager2':
      min = 12;
      break;
    default:
      min = 17;
      break;
    }
    const formattedBirthdate = formatToUS(birthdate);
    if (!formattedBirthdate) return false;

    const actualYear = moment().year();
    const birthDate = moment(formattedBirthdate, 'YYYY-MM-DD');

    const minAge = actualYear - (min + 1);
    const maxAge = actualYear - max;

    const checkMin = min ? birthDate.year() <=  minAge : true;
    const checkMax = max ? birthDate.year() >= maxAge : true;

    return (checkMin && checkMax);
  }

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    getLessonSlot({ id: lessonSlot.id })
    if (lessons && booking.birthdate) {
      const { age } = lessons.find(lesson => lesson.id === freshDataLessonSlot.lessonId)
      if (!verifyAge(booking.birthdate, age)) {
        setLoading(false);
        return setError('L’âge du participant ne correspond pas au cours choisi.');
      }
    }
    if (freshDataLessonSlot.studentsCount >= freshDataLessonSlot.places) {
      setLoading(false);
      return setError('Il n\'y a plus de place disponible pour ce cours.');
    }

    await lockLessonPlace({ id: freshDataLessonSlot.id });

    // set booking in session storage
    sessionStorage.setItem(`school_booking`, JSON.stringify({
      ...booking,
      comment: `Inscription prise par ${user.firstname} ${user.lastname} (${user.email}) - ${booking.comment}`,
    }));
    
    return window.location.replace(
      `${window.location.origin}/${golf.slug}/payment?lsId=${freshDataLessonSlot.id}&event=signing_done`
    );
    
    // return true;
  };

  return (
    booking &&
    <>
      <ButtonBar
        justify='center'
        buttons={[{
          label: 'Je réserve pour moi-même',
          variant: disabled === 'left' ? 'contained' : 'text',
          onClick: () => handleClick(false),
          disabled: disabled === 'left'
        }, {
          label: 'Je réserve pour un tiers',
          variant: disabled === 'right' ? 'contained' : 'text',
          onClick: () => handleClick(true),
          disabled: disabled === 'right'
        }]} />

      <Grid container direction="column">
        <Grid item>
          <Form
            onChange={handleChange}
            onSubmit={submitForm}
            className={classes.form}
            onClose={handleClose}
            closeBtnLabel='Retour'
            error={error}
            loading={loading}
            fields={[
              {
                name: 'lastname',
                label: 'Nom',
                value: booking.lastname || '',
                required: true,
                disabled: !thirdParty && !!booking.lastname
              },
              {
                name: 'firstname',
                label: 'Prénom',
                value: booking.firstname || '',
                required: true,
                disabled: !thirdParty && !!booking.firstname
              },
              {
                name: 'address',
                label: 'Adresse',
                value: booking.address || '',
                required: false,
              },
              {
                name: 'address2',
                label: 'Complément d\'adresse',
                value: booking.address2 || '',
                required: false,
              },
              {
                name: 'postalCode',
                label: 'Code Postal',
                value: booking.postalCode || '',
                required: false,
              },
              {
                name: 'city',
                label: 'Ville',
                value: booking.city || '',
                required: false,
              },
              {
                name: 'gender',
                label: 'Genre',
                value: booking.gender || '',
                required: true,
                items: genders.slice(1),
                type: 'select',
              },
              {
                name: 'birthdate',
                label: 'Date de naissance',
                type: 'date',
                value: booking.birthdate || '',
                required: true,
              },
              {
                name: 'phone',
                label: 'Numéro de téléphone',
                value: booking.phone || '',
                required: true,
              },
              {
                name: 'comment',
                label: 'Commentaire',
                value: booking.comment || '',
              },
            ]} />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    flexWrap: 'wrap',
  },
}));
