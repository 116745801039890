import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import React from 'react';


export const ButtonBar = ({ buttons, justify = 'flex-end' }) => {

  const classes = useStyles({ justify });

  return (
    <Grid container direction="row" justify={justify} alignItems="center" className={classes.buttons}>
      {buttons.map(({
        label,
        hide,
        ...buttonProps
      }) => (hide ? null :
        <Button
          key={label}
          size="large"
          color="primary"
          variant="text"
          className={classes.button}
          {...buttonProps}>
          {label}
        </Button>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(.5),
  },
  button: {
    marginLeft: ({ justify }) => theme.spacing(justify === 'center' ? 0 : 1),
  },
}));
