import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  Typography,
  CircularProgress,
  FormControl,
} from '@material-ui/core';
import { queryLessonSlotStudents, querySchoolLessons } from '../utils/gqlQueries';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

import { Layout } from './common/Layout';
import { AppContext } from '../context/AppContext';

import {
  ages,
  levels,
  prepareFilters,
} from '../utils/filters';
import '../theme/theme.css'
import moment from 'moment';

/* */
const useStyles = makeStyles((theme) => ({
  printable: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
  },
  lessonsContainer: {
    '@media print': {
      display: 'block',
      padding: '0 !important',
    },
  },
  lesson: {
    '@media print': {
      display: 'inline-flex',
      pageBreakInside: 'avoid',
    },
  },
  root: {
    '& th,td': {
      '@media print': {
        fontSize: '8pt',
      },
    }
  },
  divider: {
    margin: theme.spacing(2, 0),
    '@media print': {
      display: 'none',
    },
  },
}));

export const AdminLessonsList = () => {
  const classes = useStyles();

  const {
    golf,
    fetchApi,
  } = useContext(AppContext);

  const [lessons, setLessons] = useState([]);
  const [filteredLessons, setFilteredLessons] = useState({});
  const [loadingStudents, setLoadingStudents] = useState(true);
  const [students, setStudents] = useState([]);

  const [selectedDay, setSelectedDay] = useState(-1);
  const [days, setDays] = useState([{ key: -1, value: 'Chargement...' }]);

  const [filters] = useState({
    teachers: -1,
    age: ages[0].value,
  });

  /* */
  useEffect(() => {
    getLessons();
  }, []);

  useEffect(() => {
    if (lessons && lessons.length > 0) {
      const filtered = {};
      for (let i = 0; i < lessons.length; i++) {
        if (!filtered[lessons[i].slot.date]) {
          filtered[lessons[i].slot.date] = [];
        }

        filtered[lessons[i].slot.date].push(lessons[i]);
      }

      setDays(Object.keys(filtered).map((k) => {
        const m = moment(k);
        const day = m.format('dddd');
        return {
          key: k,
          value: `Cours du ${day.substr(0, 1).toUpperCase()}${day.substr(1)}`,
          lessonsCount: filtered[k].length,
        }
      }));

      setSelectedDay(Object.keys(filtered)[0]);
      setFilteredLessons(filtered);
    }
  }, [lessons]);

  useEffect(() => {
    if (selectedDay !== -1 && filteredLessons && selectedDay && filteredLessons[selectedDay]) {
      setLoadingStudents(true);
      fetchApi(queryLessonSlotStudents, ({
        ids: filteredLessons[selectedDay].map((fl) => fl.slot.id),
      })).then((response) => {
        if (!response || !response.data) return;
        const res = response.data.getSchoolLessonSlotStudents;

        const newFilteredLessons = filteredLessons;
        for (let i = 0; i < newFilteredLessons[selectedDay].length; i++) {
          newFilteredLessons[selectedDay][i].slot.students = res.filter((s) => s.lessonSlotId === newFilteredLessons[selectedDay][i].slot.id) || [];
        }

        for (let i = 0; i < res.length; i++) {
          res[i].lesson = newFilteredLessons[selectedDay].find((l) => l.slot.id === res[i].lessonSlotId);
        }

        let sortedStudents = [...res];
        sortedStudents = sortedStudents.sort((a, b) => a.firstname.localeCompare(b.firstname));
        sortedStudents = sortedStudents.sort((a, b) => a.lastname.localeCompare(b.lastname));
        setFilteredLessons(newFilteredLessons);
        setStudents(sortedStudents.sort((a, b) => {
          const ageA = ages.indexOf(ages.find((age) => age.value === a.lesson.age));
          const ageB = ages.indexOf(ages.find((age) => age.value === b.lesson.age));

          return ageA - ageB;
        }));

        // const dIndex = days.findIndex((d) => d.key === selectedDay);
        // days[dIndex].lessonsCount = newFilteredLessons[selectedDay].filter((l) => l.slot.students && l.slot.students.length > 0).length;

        setLoadingStudents(false);
      });
    }
  }, [selectedDay])

  /* */
  const getLessons = () => fetchApi(querySchoolLessons, ({
    recurrent: 1,
    golfId: golf.id,
    ...prepareFilters(filters, true),
  })).then((response) => {
    if (!response || !response.data) return;
    const l = response.data.getSchoolLessons;
    for (let i = 0; i < l.length; i++) {
      if (l[i] && l[i].slot && l[i].slot.date) {
        l[i].slot.date = moment(parseInt(l[i].slot.date, 10)).format('YYYY-MM-DD');
      }
    }

    setLessons(l);
    return l;
  });

  const getLevel = (level) => levels.find((l) => l.value === level).label;
  const getAge = (age) => ages.find((a) => a.value === age).label;

  const daySelected = (event) => {
    setSelectedDay(event.target.value);
  }

  // const print = (data) => {
  //   return Promise.resolve().then(() => {
  //     setPrintData(data);
  //   }).then(() => {
  //     window.print();
  //   });
  // }

  const xljs = async (data) => {
    const wb = new Workbook();

    let filename = '';
    if (data === 'lessons') {
      filename = `cours-${selectedDay}`;
      const ws = wb.addWorksheet(`Cours du ${selectedDay}`);

      ws.columns = [
        // { header: 'id', key: 'id', width: 20 },
        { header: 'Titre', key: 'title', width: 20 },
        { header: 'Heure', key: 'hour', width: 20 },
        { header: 'Professeur', key: 'teacher', width: 20 },
        { header: 'Niveau', key: 'level', width: 20 },
        { header: 'Âge', key: 'age', width: 20 },
        { header: 'Élèves', key: 'students', width: 20 },
      ];

      ws.addRows(filteredLessons[selectedDay].map((fl) => ({
        id: fl.id,
        title: fl.title,
        hour: `${fl.slot.startHour.substr(0, 5)} - ${fl.slot.endHour.substr(0, 5)}`,
        teacher: fl.teacher,
        level: getLevel(fl.level),
        age: getAge(fl.age),
        students: `${fl.slot.students ? fl.slot.students.length : 0}/${fl.places}`,
      })));
    }

    if (data === 'students') {
      filename = `eleves-${selectedDay}`;
      const ws = wb.addWorksheet(`Cours du ${selectedDay}`);

      ws.columns = [
        // { header: 'id', key: 'id', width: 20 },
        { header: 'Nom', key: 'lastname', width: 20 },
        { header: 'Prénom', key: 'firstname', width: 20 },
        { header: 'Commentaire', key: 'comment', width: 20 },
        { header: 'Niveau', key: 'level', width: 20 },
        { header: 'Âge', key: 'age', width: 20 },
        { header: 'Cours', key: 'lesson', width: 20 },
        { header: 'Professeur', key: 'teacher', width: 20 },
        { header: 'Heure', key: 'hour', width: 20 },
      ];

      ws.addRows(students.map((student) => ({
        id: student.id,
        lastname: student.lastname,
        firstname: student.firstname,
        comment: student.comment,
        level: getLevel(student.lesson.level),
        age: getAge(student.lesson.age),
        lesson: student.lesson.title,
        teacher: student.lesson.teacher,
        hour: student.lesson.slot.startHour.substr(0, 5),
      })));
    }

    if (data === 'lessons-with-students') {
      filename = `planning-${selectedDay}`;

      for (let i = 0; i < filteredLessons[selectedDay].length; i++) {
        const fl = filteredLessons[selectedDay][i];
        if (fl.slot.students && fl.slot.students.length > 0) {
          const wsTitle = `${fl.teacher} - ${fl.slot.startHour.substr(0, 5)} - ${getLevel(fl.level)} - ${getAge(fl.age)}`;
          const ws = wb.addWorksheet(wsTitle
            .replace(':', '')
          );

          ws.columns = [
            { header: 'A', key: 'a', width: 20 },
            { header: 'B', key: 'b', width: 20 },
            { header: 'C', key: 'c', width: 20 },
            { header: 'D', key: 'd', width: 20 },
          ]

          ws.addTable({
            name: 'lesson-details',
            ref: 'A1',
            headerRows: false,
            columns: [
              { name: 'Professeur', width: 300 },
              { name: 'Heure' },
              { name: 'Niveau' },
              { name: 'Âge' },
            ],
            rows: [
              [fl.teacher, fl.slot.startHour.substr(0, 5), getLevel(fl.level), getAge(fl.age)],
            ]
          });

          ws.addTable({
            name: 'students',
            ref: 'A4',
            columns: [
              { name: 'Nom' },
              { name: 'Prénom' },
            ],
            rows: fl.slot.students.map((student) => ([
              student.lastname,
              student.firstname,
            ])),
          });
        }
      }
    }

    await wb.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${filename}.xlsx`);
    });
  }

  return (
    <Layout>
      <Typography variant="h4" className={'not-printed'}>
        {'Liste des cours'}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={2} justify="flex-start" alignItems="flex-start" className={'not-printed'}>
        <Grid item xs>
          <FormControl variant="outlined">
            <InputLabel id="select-date-label">Choisissez une date</InputLabel>
            <Select
              labelId="select-date-label"
              label="Choisissez une date"
              value={selectedDay}
              onChange={daySelected}
            >
              {days && days.map((d) => (
                <MenuItem key={d.key} value={d.key}>{d.value} ({d.lessonsCount} cours)</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs>
          <Button disabled={loadingStudents} variant="contained" color="primary" onClick={() => xljs('lessons')}>
            {loadingStudents ? (
              <CircularProgress size={24} color='inherit' />
            ) : (
              <>
                {'Excel - Cours'}
              </>
            )}
          </Button>
        </Grid>

        <Grid item xs>
          <Button disabled={loadingStudents} variant="contained" color="primary" onClick={() => xljs('students')}>
            {loadingStudents ? (
              <CircularProgress size={24} color='inherit' />
            ) : (
              <>
                {'Excel - Élèves'}
              </>
            )}
          </Button>
        </Grid>

        <Grid item xs>
          <Button disabled={loadingStudents} variant="contained" color="primary" onClick={() => xljs('lessons-with-students')}>
            {loadingStudents ? (
              <CircularProgress size={24} color='inherit' />
            ) : (
              <>
                {'Excel - Planning'}
              </>
            )}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={`${classes.root} ${classes.printable}`}>
        <Grid item xs>
          <Typography variant="h4">{days.find((d) => d.key === selectedDay).value}</Typography>
        </Grid>
      </Grid>
    </Layout>
  );
}
