export const gameConditions = golf => {
  const option = golf.options.find(o => o.name === 'gameConditions');
  return JSON.parse(option.value);
};

/**
 * @returns a list of unique types
 */
export function getTypes(gameConditions) {
  let array = [];
  for (let condition in gameConditions) {
    array.push(gameConditions[condition].type);
  }
  return [...new Set(array.map(obj => JSON.stringify(obj)))].map(str => JSON.parse(str));
}
