import React from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Redirect } from 'react-router-dom';
import {
  Link,
  Paper,
  Avatar,
  Typography,
} from '@material-ui/core';
import RegisterForm from './common/RegisterForm';
import { createValidationSchema, validators } from '../utils/validation';
import { AppContext } from '../context/AppContext';
import { setAccountEdg } from '../utils/gqlQueries';
import Alert from './common/Alert';

/* */
const validationSchema = createValidationSchema({
  email: validators.email,
  password: validators.password,
  firstname: validators.required,
  lastname: validators.required,
  phone: validators.required,
});

/* */
const styles = theme => ({
  container: {
    width: '30%',
    minWidth: '300px',
    margin: '8% auto',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(4),
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  avatar: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
});

/* */
class Register extends React.Component {

  static contextType = AppContext;

  /* */
  state = {
    error: null,
  };

  /* */
  onSubmit = (values, { resetForm, setSubmitting }) => {
    const { signin, fetchApi, golf } = this.context;
    /**
     * @todo: use primapp-api (4200) register endpoint
     */
    fetchApi(setAccountEdg, {
      activationData: {
        golfId: golf.id,
        reference: 'UNKWOWN',
      },
      ...values,
    }, null, process.env.REACT_APP_AUTH_URL)
      .then(response => {
        if (response.data.setAccountEdg) {
          signin(values)
            .then(() => { });
        } else {
          console.error(response);
          if (response.errors && response.errors
            .findIndex((e) => e.message === 'errors.user.already_registered') > -1
          ) {
            this.setState({
              error: 'Adresse email déjà utilisée',
            });
          }
          setSubmitting(false);
        }
      })
      .catch((e) => {
        resetForm();
        setSubmitting(false);
      });
  };

  /* */
  render() {
    const { classes } = this.props;
    const { user, golf } = this.context;
    const { error } = this.state;

    if (user) { return <Redirect to={`/${golf.slug}/dashboard`} /> };

    return (
      <div className={classes.container}>
        <Typography component="h1" variant="h5" align="center">
          {golf && golf.name}
        </Typography>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography gutterBottom component="h2" variant="h5">
            {'Inscription visiteur'}
          </Typography>
          <Typography gutterBottom variant="body2" align="justify">
            {'Créez votre compte utilisateur pour vous inscrire ou inscrire vos enfants à votre école de golf.'}
          </Typography>
          <Formik
            initialValues={{
              email: '',
              password: '',
              lastname: '',
              firstname: '',
              phone: '',
            }}
            component={RegisterForm}
            onSubmit={this.onSubmit}
            validationSchema={validationSchema} />
          <Box mt={2}>
            <Typography>
              {'En continuant, vous acceptez nos '}
              <Link
                rel="noopener"
                target="_blank"
                color="textSecondary"
                href="/terms-conditions">
                {'Conditions générales d\'utilisation'}
              </Link>
              {' et notre '}
              <Link
                rel="noopener"
                target="_blank"
                color="textSecondary"
                href="/privacy-policy">
                {'Politique de confidentialité.'}
              </Link>
            </Typography>
          </Box>
        </Paper>
        <Box textAlign="center">
          <Typography>
            {'Déjà inscrit ?'}
          </Typography>
          <Link
            to={`/${golf.slug}/`}
            color="textSecondary"
            component={RouterLink}>
            {'Connectez-vous'}
          </Link>
        </Box>
        {error ? (
          <Alert severity="error" autohide="true" position="absolute">{error}</Alert>
        ) : (null)}
      </div>
    );
  }
}

export default withStyles(styles)(Register);
