import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';


const getClient = (golf = null, user = null, token = null, api = null) => {
  const httpLink = new HttpLink({ uri: `${api || process.env.REACT_APP_API_URL}/graphql` });

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'x-access-token': token || user.token || '',
      }
    }
  })

  const golfLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        'x-golf': golf ? golf : '',
      }
    }
  })

  const errorLink = onError(({ networkError }) => {
    if (networkError) {
      return console.error(networkError.result.message || networkError);
    }
    return null;
  })

  const uploadLink = createUploadLink({ uri: `${api || process.env.REACT_APP_API_URL}/graphql` });

  const links = from([
    errorLink,
    authLink,
    golfLink,
    uploadLink,
    httpLink,
  ])

  const pws = new ApolloClient({
    link: links,
    cache: new InMemoryCache(),
  })

  return pws;
};

export { getClient };
