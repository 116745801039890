import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';


export const WeatherPrevisionsWidget = ({ icon, day, temp }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Typography variant="caption">{day}</Typography>
      <img alt='icon' src={icon} className={classes.icon} />
      <Typography variant="subtitle1" component='div'>
        <Box fontWeight="fontWeightMedium">{temp}°</Box></Typography>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  icon: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
}));
