import React, { createContext, useEffect, useState } from 'react';

import firebase, { autoRefreshToken, stopAutoRefreshToken } from '../utils/firebase';
import { Loading } from '../components/common/Loading';
import { queryGetAccount, queryGetAccountByToken, queryGetGolf, querySchoolTeachers, queryWeather } from '../utils/gqlQueries';
import { getClient } from '../utils/pwsClient';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';


export const AppContext = createContext();

export const AppProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [weather, setWeather] = useState(null);
  const [golf, setGolf] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [loadingGolf, setLoadingGolf] = useState(true);
  const [init, setInit] = useState(true);

  const history = useHistory();

  const isAdmin = () => user && user.role === 'admin';

  const fetchApi = (query, variables = {}, token = null, api = null) => {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query, variables }),
      fetchPolicy: 'no-cache'
    };

    if (user || token) {
      const tkn = token || user.token;
      options.headers['x-access-token'] = `${tkn}`;
    }

    if (golf) {
      options.headers['x-golf'] = `${golf.id}`;
    }

    return new Promise((resolve, reject) => (
      fetch(`${api || process.env.REACT_APP_API_URL}/graphql`, options)
        .catch((er) => {
          reject(er);
        })
        .then(r => {
          if (r && r.status && r.status === 401) {
            signout();
            reject(new Error('unauthorized'));
          }
          return r;
        })
        .then(r => r && resolve(r.json()))
    )).catch(console.error);
  }


  const uploadFile = (mutation, variables = {}, token = null, api = null) => {
    const client = getClient(golf, user, token, api);

    return new Promise((resolve, reject) => (
      client.mutate({
        mutation: gql`${mutation}`,
        variables,
      }).then(r => {
        if (!r || r.status === 401) {
          signout();
          reject(new Error('unauthorized'));
        }
        return r;
      })
    )).catch(console.error);
  }


  /**
   * User auth functions
   */
  const signin = ({ email, password }) => fetch(`${process.env.REACT_APP_AUTH_URL}/login`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-golf': golf ? `${golf.id}` : undefined,

    },
    body: JSON.stringify({
      username: email,
      password,
    })
  }).then(r => r.json())
    .then(({ code, data }) => {
      if (code !== 200) throw data.error;
      return data.token;
    }).then(token => firebase.auth().signInWithCustomToken(token));

  const signout = () => {
    firebase.auth().signOut();
  };

  const getTeachers = () => {
    fetchApi(querySchoolTeachers, { golfId: golf.id })
      .then(response => setTeachers(response.data.getSchoolTeachers))
      .catch(err => console.error('Impossible de récupérer les professeurs.', err));
  };

  const getWeather = () => {
    fetchApi(queryWeather, { id: golf.id })
      .then(response => setWeather(response.data.getGolfWeather))
      .catch(err => console.error('Impossible de récupérer la météo.', err));
  };

  useEffect(() => {
    /**
     * Get the golf from the slug in url
     */
    const [, slug,] = window.location.pathname.split('/');
    fetchApi(queryGetGolf, { id: slug })
      .then(response => {
        setGolf(response?.data?.getGolf);
        setLoadingGolf(false);
      });
  }, []);

  useEffect(() => {
    if (!loadingGolf) {
      /**
       * Authentication
       */
      firebase.auth()
        .onAuthStateChanged(async _user => {
          setLoadingUser(true);
          if (_user && golf) {

            const token = await _user.getIdToken();
            fetchApi(queryGetAccount, { golfId: golf.id }, token, process.env.REACT_APP_AUTH_URL)
              .then((response) => {
                setLoadingUser(false);
                if (!response) console.error(`Impossible de se connecter à l'api`);
                if (response.errors) {
                  console.error(response.errors);
                  return setInit(false);
                }
                if (!response.data.getAccountEdg) {
                  setUser(null);
                  setInit(false);
                  return;
                } else {
                  const me = response.data.getAccountEdg;
                  me.token = token;
                  // console.log(me);
                  if (me.isAdminEdg) {
                    me.role = 'admin';
                  }

                  autoRefreshToken(_user, me, setUser);

                  return setUser(me);
                }

              });

          } else {
            const { location } = history;
            if (location?.search) {
              const { token } = queryString.parse(location.search);
              if (token) {
                fetchApi(queryGetAccountByToken, { golfId: golf.id, token: token })
                  .then(({ data: { getAccountEdgByToken } }) => {
                    if (getAccountEdgByToken) {
                      if (getAccountEdgByToken.token) {
                        return firebase.auth().signInWithCustomToken(getAccountEdgByToken.token)
                          .then(() => {
                            setTimeout(() => {
                              history.push({
                                pathname: location.pathname,
                                search: queryString.exclude(location.search, ['token']),
                              });
                            }, 1500);
                          });
                      }
                    }
                  })
              }
            }
            setLoadingUser(false);
            stopAutoRefreshToken();
            setInit(false);
          }
          setUser(null);
        });
    }
  }, [loadingGolf]);

  /**
   * Get data to distribute when golf is ready
   */
  useEffect(() => {
    if (!golf || !user) return;
    getTeachers();
    getWeather();
  }, [golf, user]);

  /**
   * Discard init state when user's loaded
   */
  useEffect(() => {
    if (user && init) setInit(false);
  }, [user]);

  if (init || loadingGolf) return <Loading />;

  return (
    <AppContext.Provider value={{
      user,
      loadingUser,
      golf,
      teachers,
      weather,
      getWeather,
      getTeachers,
      signin,
      signout,
      fetchApi,
      isAdmin,
      uploadFile,
    }}>
      {children}
    </AppContext.Provider>
  );
};
