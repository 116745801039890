import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


export const WidgetTitle = ({ children }) => {

  const classes = useStyles();

  return (
    <Typography variant="h6" className={classes.title}>
      <Box fontWeight="fontWeightBold">
        {children}
      </Box>
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    marginBottom: 10,
  },
}));
