import * as qs from 'query-string';
import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { CircularProgress } from '@material-ui/core';


const Response3DS = ({ location }) => {
  const queryParams = qs.parse(location.search);
  const { status, id3D } = queryParams;

  useEffect(() => {
    const data = {
      id3D,
      status,
      type: 'response3DS',
    };
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } else {
      window.parent.postMessage(data, '*');
    }
  });

  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <CircularProgress
        size={40}
        thickness={4}
        color="inherit" />
    </Box>
  );
};

export default Response3DS;
