import React, { useContext } from 'react';
import { Typography, Avatar, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import image from '../../assets/avatar_temp.jpg';
import background from '../../assets/grass.jpg';
import { AppContext } from '../../context/AppContext';


export const ProfilePaper = () => {
  const classes = useStyles();
  const { user } = useContext(AppContext);

  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid container direction="column" justify="space-between" alignItems="center">
        <Grid item>
          <Avatar alt="Remy Sharp" src={image} className={classes.avatar} />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            Bonjour,
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" data-testid="userRole">{user.firstname} {user.lastname}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    margin: theme.spacing(2),
    boxShadow: theme.shadows[1],
  },
  paper: {
    display: 'flex',
    backgroundImage: `url(${background})`,
    color: theme.palette.common.white,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));
