import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { CalendarToday, Event, Repeat, Schedule } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { formatDate, formatTime, getDayOfWeek } from '../../utils/date';
import { BookingDate } from './BookingDate';


export const BookingItem = ({ booking }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>

      <Grid container direction="row" justify="space-between" alignItems="center">

        <Grid item xs={1}>
          <Grid container direction="column" justify="space-between" alignItems="center">
            {booking.recurrent ? <Repeat /> : <Event />}
            <Typography variant="caption">
              {booking.recurrent ? <div>Récurrent</div> : <div>Unique</div>}
            </Typography>

          </Grid>
        </Grid>

        <Grid item xs>
          <Grid container direction="column" justify="space-between" alignItems="flex-start">
            <Typography variant="h6">
              {booking.lesson}
            </Typography>
            <Typography variant="body1">
              {booking.name}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs>
          <Grid container direction="column" justify="center" alignItems="flex-start">
            {
              booking.recurrent ? (
                <BookingDate icon={<CalendarToday className={classes.date} />} text={`Tous les ${getDayOfWeek(+booking.date)}`} />
              ) : (
                <BookingDate icon={<CalendarToday className={classes.date} />} text={`Le ${formatDate(+booking.date, 'D/MM/YYYY')}`} />
              )
            }
            <BookingDate icon={<Schedule className={classes.date} />} text={`De ${formatTime(booking.startHour)} à ${formatTime(booking.endHour)}`} />
          </Grid>
        </Grid>

        {/* <Grid item xs={1}>
          <Button>Modifier</Button>
        </Grid> */}

      </Grid>

    </Paper>
  );
};


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2, 2, 0),
    margin: theme.spacing(2, 0)
  },
  date: {
    margin: theme.spacing(0.5),
  },
}));
