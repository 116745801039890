import React from 'react';
import { IconButton } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import { DisconnectDialog } from './DisconnectDialog';


export const DisconnectButton = ({ signout }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <IconButton color='inherit' onClick={handleClickOpen}>
        <PowerSettingsNewIcon fontSize='large' />
      </IconButton>
      <DisconnectDialog open={open} onClose={() => setOpen(false)} />
    </div>
  );
};
