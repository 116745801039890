import { Avatar, Card, Typography, Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';


export const TeacherItem = ({ teacher, onClick, url }) => {

  const classes = useStyles();

  return (
    <Card className={classes.card} elevation={2}>
      <Grid container direction="row" justify="space-evenly" alignItems="center">
        <Grid item>
          <Avatar alt={teacher.name} src={teacher.image} className={classes.avatar} />
        </Grid>
        {/* <Grid item>
          <TeacherStatistic experience={teacher.experience} handicap={teacher.handicap}/>
        </Grid> */}
      </Grid>
      <Grid container direction="column" alignItems="center">
        <Typography variant="h6">
          {teacher.name}
        </Typography>
        {/* <Typography variant="caption">
          {teacher.job}
        </Typography> */}
      </Grid>
      {/* <Typography variant="body1" className={classes.description}>{teacher.description}</Typography> */}
      <Button size="small" color="primary" variant="contained" className={classes.button} onClick={() => onClick(teacher.id)}>
        <Link to={url} className={classes.link}>
          Voir le planning
        </Link>
      </Button>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: theme.spacing(2),
    boxShadow: theme.shadows[1],
  },
  description: {
    margin: theme.spacing(2),
  },
  button: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  card: {
    position: 'relative',
    width: theme.spacing(50),
    height: theme.spacing(32),
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    alignContent: 'center',
  },
  link: {
    textDecoration: 'none',
    color: 'white'
  },
}));
