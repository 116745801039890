/**
 * Simple object merger to avoid using lodash
 *
 * @param target
 * @param source
 * @returns {*}
 */
export const mergeDeep = (target, source) => {

  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target)) return source;
  if (!isObject(source)) return target;

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}
