import React, { createContext, useContext, useState, useEffect } from 'react';
import { AppContext } from './AppContext';
import {
  queryGetNumcotisList,
} from '../utils/gqlQueries';


export const BookingContext = createContext();

export const BookingProvider = ({ children }) => {

  const { user, golf, fetchApi } = useContext(AppContext);
  const [booking, setBooking] = useState(null);
  const [numcotisList, setNumcotisList] = useState([]);
  const [hasSignedInternalRules, setHasSignedInternalRules] = useState(false);

  const clearBooking = () => setBooking(null);


  const getNumcotisList = async () => {
    if ((golf && golf.id) && (user && user.id)) {
      const list = await fetchApi(queryGetNumcotisList, { accountId: user.id, golfId: golf.id })
        .then(res => res.data.getNumcotisList)
        .then(res => res ? res.reduce((acc, cum, ind) => {
          acc[ind] = {
            value: cum.pgbCode,
            label: `${cum.pgbCode} - ${cum.name}`,
          }
          return acc;
        }, []) : []);
      setNumcotisList(list);
    }
  };

  useEffect(() => {
    if (user && user.isAdminEdg) {
      getNumcotisList();
    }
  }, [user])

  return (
    <BookingContext.Provider value={{
      booking,
      setBooking,
      numcotisList,
      getNumcotisList,
      clearBooking,
      hasSignedInternalRules,
      setHasSignedInternalRules
    }}>
      {children}
    </BookingContext.Provider>
  );
};