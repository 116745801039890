import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { AppContext } from '../../context/AppContext';
import image from '../../assets/school.jpg';
import { SimpleWidget } from './SimpleWidget';


export const EcoleWidget = () => {

  const classes = useStyles();
  const { golf } = useContext(AppContext);

  return (
    <SimpleWidget
      image={image}
      link={`/${golf.slug}/ecole`}
      linkLabel="S'inscrire à l'école">

      <Typography variant="subtitle2" className={classes.accroche}>
        Inscrivez-<span>vous</span> ou <span>vos enfants</span> à notre école afin de progresser <span>toute l'année</span>.
      </Typography>

    </SimpleWidget>
  );
};

const useStyles = makeStyles((theme) => ({
  accroche: {
    color: theme.palette.common.black,
    margin: '20px 0',
    '& span': {
      color: theme.palette.primary.main,
    }
  },
}));
